.right-panel-title {
    line-height: 36px;
}

a.suppress-link, a.suppress-link:hover {
    color: inherit;
    text-decoration: none;
}

.transaction-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    max-width: 92vw;
    overflow-x: auto;
}
