.searchSection{
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: '#fff';
    /* flexDirection: 'row';
    justifyContent: 'center';
    alignItems: 'center';
    backgroundColor: '#fff'; */
}
.searchIcon {
    padding:10;
    height: 20px;
    width: 20px;
}
.input {
    flex: 1;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    background-color: #fff;
    color: #424242;
    /* paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#424242', */
}
.row-center{
    display: flex;
    flex-direction: row;
    width: '100%';
    justify-content: center;
    
}
.CenterView{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
 }
 .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 12px;
  }
  
  .item {
    height: 40px;
    background-color: #eee;
  }

  .base-root-view-padding {
    padding: 20px 40px;
    display: flex;
    flex: 1;
    /* overflow: hidden; */
  }

  .base-root-view-container{
    background-color: white; 
    align-self: center; 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .base-root-padding {
    padding: 20px 0px 20px 75px
  }

  .base-root-container{
    background-color: white; 
    align-self: center; 
    border-radius: 10px;
    width: 80%;
  }

  .base-root-view-head{
    width: 100%;
    height: 50px; 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .base-root-view-container > div:last-child {
    flex: 1;
    overflow: auto;
  }

  .base-root-head-body{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .m-l-20-px{
    margin-left: 20px;
  }

  .m-l-5-px{
    margin-left: 5px;
  }

  .base-root-head-label{
    color: white;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 14px;
  }

  .base-root-detail{
    margin: 10px;
    display: flex; 
    flex-direction: column;
    padding: 0px 0px 0px 3px;
  }

  .base-root-divider{
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: #DADDDF;
  }

  .base-root-label{
    font-family: 'Manrope';
    font-weight: 700; 
    font-size: 15px;
    color: #111012;
  }
  
  .red-color{
    color: #DC2626 !important;
  }

  .bg-red-color{
    background-color: #DC2626;
  }

  .bg-yellow-color{
    background-color: #F7C10F;
  }

  .f-w-400{
    font-weight: 400 !important;
  }

  .m-t-b{
    margin: 10px 0px 10px 0px;
  }

  .m-0-px{
    margin: 0px;
  }

  .mb-p{
    margin-bottom: 10px;
    padding: 0px 12px 0px 15px;
  }

  .mb-10-px{
    margin-bottom: 10px;
  }

  .p-2-px{
    padding: 2px;
  }

  .renew-action-container button {
    padding: 4px 8px !important;
  }