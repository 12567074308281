.private-lots-page {
    display: flex;
    margin: 0px !important;
    overflow: auto;
}

.private-lots-body{
    flex: 1;
    display: flex;
    padding-bottom: 0px;
}

.private-lots-body.p-map-body {
    overflow: hidden;
}

.private-rt-header-container {
    /* width: 400px !important; */
    padding-left: 200px;
    width: calc(100% - (100% - 300px)) !important;
}

.private-rt-header-container-25 {
    /* width: 400px !important; */
    padding-left: 25px !important;
}

.private-lots-parent-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding-right: 1rem;
    padding-left: 1rem;
}

.private-lots-parent-right {
    max-width: 400px;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    position: relative;
    overflow-y: auto;
}

/* .private-lots-parent-right.p-map-screen {
    overflow: hidden !important;
} */

.right-panel-subcontainer {
    position: absolute;
    width: 100%;
}

.notify-toaster-pl,
.notify-toaster-pl>.MuiSnackbarContent-root {
    width: 315px;
    height: 160px;
    top: 196px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #323232;
    text-align: center;
    right:40px !important;
}

.notify-toaster-pl{
    z-index:9 !important;
}


.notify-toaster-pl>.MuiSnackbarContent-root>.MuiSnackbarContent-message {
    flex: 1;
}

.private-lots-parent-container .data-grid-paper-container{
    box-shadow: none !important;
}

.private-lots-right-panal {
    width: 366px !important;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.details-tenant-name {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #323232;
}

.details-tenant-email {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #111012;
}

.padd-tenant-container {
    padding: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    padding-bottom: 20px;
}

.padd-tenant-heading {
    font-size: 24px;
    font-weight: 700;
    padding: 0px 20px 0px 20px;
    margin: 0px;
}

.padd-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #323232;
}

.padd-tenant-exisiting-msg {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #323232;
}
.remove-access-item {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF4242 !important;
}

.add-tenant-pl-panel-body {
    padding: 0px 20px 10px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.add-tenant-block-pl:first-child {
    margin-top: 10px;
}

.add-tenant-block-pl:last-child {
    margin-bottom: 10px;
}

.add-tenant-block-pl .MuiFormControl-marginNormal {
    margin-top: 5px;
}

.panel-body-field-label {
    font-size: 14px;
    font-weight: 700;
    color: #323232;
    margin: 15px 0px 5px 0px;
    text-align: left !important;
}

.add-tenant-subheading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #323232;
}

.bulk-upload-blocker{
    position: fixed;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1202;
}

.bulk-upload-popup {
    background: #ffffff;
    padding: 40px;
    display: flex;
    border-radius: 10px;
}

.bulk-upload-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.0324em;
    color: #111012;
}

.bulk-upload-sub-content {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #323232;
}

.bul-upload-primary-btn {
    width: 131px;
    height: 42px;
    border-radius: 10px;
}



.link-color{
    font-size: 15px !important;
    text-decoration: underline !important;
    color: #00AF85 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
}

/* .file-dropbox > .file-drop-target {
    position: relative;
    height: 100%;
    border: 1px dashed #cccccc;
}

.file-dropbox > .file-drop-target.file-drop-dragging-over-frame {
    border-color: #000000;
}

.file-dropbox > .file-drop-target.file-drop-dragging-over-target {
    border-color: #00af85;
} */

/* .bulk-upload-file-container {
    box-sizing: border-box;
    width: 340px;
    height: 177px;
    border: 1px dashed #8A959E;
} */
.bulk-upload-file-container{
    width: 90%;
}

.bulk-upload-file-container .file-dropbox {
    min-height: 100px;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target {
    position: relative;
    height: 200px;
    border: 1px dashed #8A959E;
    display: flex;
    flex: 1;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target.file-drop-dragging-over-frame {
    border-color: #000000;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target.file-drop-dragging-over-target {
    border-color: #00af85;
}

.bulk-upload-file-container .dropbox-content {
    /* width: 340px; */
    height: 177px;
    flex:1;
}

.multi-remove-access-data{
    border-bottom: 1px solid #F0F0F0;
    line-height: 35px;
}
.selected-properties {
    white-space: nowrap;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}

.drawer-panel-description {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #323232;
}

.header-p-border-bottom{
    border-bottom: 1px solid #F8F8F8;
}

.header-p-border-top{
    border-top: 1px solid #F8F8F8;
}

.pl-more-details,
.pl-more-details:hover,
.try-again {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    text-decoration: underline !important;
    color: #00AF85 !important;
    background-color: #fff !important;
    cursor: pointer;
}

.pl-show-more,
.pl-show-more:hover{
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    color: #00AF85 !important;
    background-color: #fff !important;
    cursor: pointer;
}
.override-btn {
    width: 380px;
    margin: auto;
}

.add-tenant-container {
    text-align: right;
}

.add-tenant,
.remove-tenant {
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    color: #00AF85;
}

.remove-tenant {
    color: #DF4242;
}

.del-box-right {
    position: relative;
    min-height: 20px;
    border-top: 2px solid #6B7278;
    margin-top: 20px;
    padding-top: 5px;
}

.del-box-right > div {
    position: absolute;
    right: 0px;
}

.private-lot-header-container.t-view {
    padding-top: 0px;
    padding-bottom: 7px;
}

.multi-select-blocker {
    background: #F5F5F5;
    opacity: 0.6;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.file-picker-container{
    margin: auto;
    position: relative;
    height: 198px !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;    
  }
.file-picker-container.container-padding{
    padding: 85px 0px 85px 0px;
}

.file-picker-container button{
    width: 140px;
}

.file-picker-container span{
    text-decoration: underline;
    cursor: pointer;
}

.common-toaster-pl,
.common-toaster-pl>.MuiSnackbarContent-root {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    background: #00AF85;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* width: 353px; */
    height: 51px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    text-decoration: underline !important;
}

.privatelotPage {
    padding: 0 0 0 0 !important;
    height: 46px;
    border-radius: 10px !important;
    margin-top: 0.4rem!important;
}

.InputAdornment svg{
    margin: 0px 0px 2px 12px;
}

.privatelotPage input{
    padding-top: 4px !important;
}

.privatelotPage button{
    padding-top: 10px;
}

.privatelotPage:hover{
    border: 1px solid #000000;
}

.privatelotPagesFocus{
    border: 2px solid #00b188 !important;
}

.removeButton{
    width: 50px !important;
    padding: 0px !important;
}

.removeWhileBg label{
    padding-right: 10px !important;
    background: none !important;
}

.swal2-icon-content .error-icon-size{
    font-size: 6.5rem !important;
}

.swal2-icon-content .check-icon-size{
    font-size: 6.5rem !important;    
}

.visitor-rt-header-container {
    /* width: 400px !important; */
    /* padding-left: 179px; */
    width: calc(100% - (100% - 328px)) !important;
}

.visitor-notify-toaster-pl,
.visitor-notify-toaster-pl>.MuiSnackbarContent-root {
    width: 288px;
    height: 160px;
    top: 196px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #323232;
    text-align: center;
    right:10px !important;
}

.visitor-notify-toaster-pl{
    z-index:9 !important;
}


.visitor-notify-toaster-pl>.MuiSnackbarContent-root>.MuiSnackbarContent-message {
    flex: 1;
}

.pr-70-pl-60-px{
    padding: 0px 70px 0px 60px;
}

.visitor-rt-header-container .makeStyles-root-5{
    padding-left: 10px !important;
    padding-right: 0px !important;
}

.visitor-rt-header-container .table-search-container{
    padding-right: 3px !important;
    padding-left: 2px !important;
    margin-right: 5px;
}

.delete-unit{    
    text-align: right;
    font-weight: 400;
}
.delete-unit a{
    color: #DF4242;
    text-decoration: none !important;
}

.delete-unit a:hover{
    color: #DF4242;
}

.p-button-container-grid {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.p-button-container {
    display: flex;
    border: 1px solid #C2C6CA;
    padding: 8px;
    color: #00AF85;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    align-items: center;
    user-select: none;
    min-width: 115px;
    justify-content: center;
    margin-top: 8px;
}

.p-button-container .p-button-icon {
    padding-right: 5px;
}

.p-up-map {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 20px;
    color: #323232;
    text-align: center;
    margin: 40px;
    padding: 15px;
    background: #fff;
}

.p-up-map h5, .p-up-map-edit h5 {
    font-size: 15px;
    font-weight: bold;
}

.p-up-map-edit {
    padding: 0px 30px;
}

.map-upload-popup {
    background: #ffffff;
    padding: 40px;
    display: block;
    border-radius: 10px;
    max-width: 400px;
    overflow: auto;
    max-height: 100%;
    min-width: 400px;
}

.map-bulk-upload-file-container {
    width: 100%;
}

.text-center {
    text-align: center;
}

.pl-map-details {
    color: #00b188 !important;
    font-weight: bold;
    white-space: nowrap;
}

.sp-m-btns button:first-child {
    margin-right: 10px;
}

.sp-m-btns button {
    border-radius: 10px;
}

.sp-m-btns button:last-child {
    color: #323232;
}
/* 'width': '100%', 'height': '50px','backgroundColor':'#00AF85',borderTopLeftRadius:'10px','borderTopRightRadius':'10px',display:'flex',flexDirection:'column',justifyContent:'center' */
.base-root-view{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: '100%';
}

.toolTip {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    min-width: 25%;
    background: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 250px;
}

.toolTip div {
    font-family: 'Manrope';
    font-style: normal;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    line-height: 28px;
    font-size: 14px;
}

.toolTip-item-container {
    margin-top: 10px;
}

.toolTip-item {
    max-width: 100%;
}

.toolTip-item-label{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    height: 100%;
    font-family: Manrope;
    font-weight: 400;
    font-size: 15px;
    color: #111012;
    align-items: center;
    width: 120px;    
    background-color: #F8F8F8;
}

.toolTip-f-w-700{
    font-weight: 700;
}

.toolTip-f-w-400{
    font-weight: 400;
}

.root-map-location {
    padding: 0px 10px 0px 0px;
    min-width: 200px;
}

.customtooltip {
    position: relative;
    display: inline-block;
  }
  
  .customtooltip .tooltiptext {
    visibility: hidden;
    width: 280px;
    background-color: #FFFFFF;
    color: #000;
    border-radius: 6px;
    padding: 16px;
    position: absolute;
    z-index: 9999999;
    bottom: 120%;
    left: -200%;
    margin-left: -60px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  
  .customtooltip:hover .tooltiptext {
    visibility: visible;
  }


  .react-tooltip {
    width: 280px;
    background-color: #FFFFFF;
    color: #000;
    box-shadow: 4px 4px 4px 4px rgb(0 0 0 / 25%);
    opacity: 1 !important;    
    border-radius: 6px !important;
    padding: 16px !important;
  }

  .private-lots-parent-right #widgetSpinnerScreen{
    background: white;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    height: 50%;
    position: relative;
  }

  .private-lots-parent-right .spinner>div {
    background: rgb(0, 0, 0, 0.5);
  }

  .p-license-plate {
    word-break: break-all;
    max-width: 450px;
  }

  .padding-right-left-50-px{
    padding: 0px 50px 0px 50px;
  }
.selected-lot {
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}

.bulk-upload-file-container .file-dropbox-sync > .file-drop-target {
    height: 60px !important;
}

.file-dropbox-sync .file-picker-container{
    height: auto !important;
}

.file-dropbox-sync .full-size{
    top: -19px !important;
}

.removeButton-Sync{
    width: 26px !important;
    padding: 0px !important;
}