.spot-detail-view,
.spot-edit-view {
    width: 500px;
}

.right-panel-title {
    line-height: 36px;
}

.spot-detail-view .rbc-time-content {
    overflow-y: visible !important;
}

.gryd-custom-popup .swal2-popup {
    padding: 30px;
    border-radius: 20px;
    width: 470px !important;
}

.gryd-custom-popup-body {
    margin-top: 20px;
    line-height: 24px;
}

.gryd-custom-popup .swal2-title {
    color: #111012 !important;
    font-weight: 800 !important;
}

.gryd-custom-popup.gryd-reset-link .swal2-actions {
    flex-direction: column-reverse;
}

.gryd-custom-popup:not(.gryd-reset-link) .swal2-styled,
.gryd-custom-popup.gryd-reset-link .swal2-cancel {
    min-width: 140px !important;
    border-radius: 10px;
    padding: 11px !important;
    font-size: 15px;
    font-weight: 500;
}

.gryd-custom-popup .swal2-cancel {
    color: #323232 !important;
}

.gryd-custom-popup.gryd-reset-link .swal2-cancel {
    width: 100%;
}

.gryd-custom-popup.gryd-reset-link .swal2-confirm {
    background: none !important;
    color: #00af85 !important;
    text-decoration: underline;
    width: auto !important;
    padding: 0px !important;
    margin: 0px !important;
    font-weight: 500;
    font-size: 15px;
    margin: 11px !important;
}

.reset-passowrd {
    color: #00AF85;
    text-decoration: underline;
    cursor: pointer;
    line-height: 21px;
    font-weight: 400;
    margin-top: 24px;
}

.reset-passowrd:hover {
    color: #7FD6C1;
}

.remove-access-item {
    color: #DF5A37;
    font-weight: 400;
}

.resend-activation-window-title {
    font-weight: 800;
    font-size: 21px;
    line-height: 32px;
    color: #111012;
    align-self: center;
    text-align: center;
}

.resend-activation-window {
    margin-bottom: 32px;
    margin-top: 32px;
    margin-right: 50px;
    margin-left: 50px;
    border-radius: 20px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.resend-activation-window-title2 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #323232;
    align-self: center;
    text-align: center;
}

.resend-activation-window-title3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #111012;
    align-self: center;
    margin-top: 16px;
    text-align: center;
}