.spotMaploader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #00AF85;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spot-map-container {
    border: 1px solid #ccc;
    flex: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.spot-map-body {
    flex: 1;
    overflow: auto;
    display: flex;
    position: relative;
    flex-basis: 0;
    /* padding-bottom: 60px; */
    /* align-items: center;
    justify-content: center; */
    min-width: 100%;
    max-width: 1px;

}

.spot-map-body * {
    pointer-events: none;
}

.spot-map-subcontainer {
    display: block;
    /* flex: 1; */
    position: absolute;
    background-repeat: no-repeat;
    /* transform-origin: top left; */
    transform-origin: top left;
    /* border: 1px solid; */
}

.spot-map-bc {
    background: linear-gradient(to right, #cccccc 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#cccccc 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #cccccc 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#cccccc 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 20px 2px, 2px 20px;
    border: 0 dashed;
    background-color: #f8f8f8;
    justify-content: center;
    align-items: center;
    display: flex;
}

.spot-map-addImg {
    color: #b6b6b7;
}

.spot-map-addImg svg {
    font-size: 72px;
    cursor: pointer;
}

.zone-list {
    display: flex;
    min-height: 50px;
    padding: 0 10px;
    align-items: center;
    flex-wrap: wrap;
    line-height: 30px;
    flex: 1;
}

.zone-list label {
    margin: 0 0;
    cursor: pointer;
    padding-right: 10px;
}

.zone-list input {
    margin-right: 10px;
}

.zone-list label:not(:last-child) {
    border-right: 2px solid #cccccc;
}

.sm-zoom-c {
    display: flex;
    /* position: absolute; */
    max-width: 150px;
    bottom: 10px;
    right: 20px;
    text-align: center;
    border-radius: 10px;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.sm-zoom-c > div {
    min-width: 50px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    user-select: none;
}

.sm-zoom-c > div:nth-child(2) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-size: 15px;
    font-weight: normal;
}

.sm-zoom-c .disabled {
    color: #ccc;
}

.s-map-filter-options {
    user-select: none;
}

.spot-map-header {
    display: flex;
}

.sm-map-edit-btn {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sm-map-edit-btn.disabled {
    pointer-events: none;
    opacity: 0.5;
    text-decoration: underline;
}

.spot-box:not(.fade) svg rect {
    pointer-events: all;
    cursor: pointer;
}

.non-box {
    pointer-events: none;
    display: flex;
}

.spot-box:hover svg rect {
    fill: rgba(0, 175, 133, 0.1);
}

.spot-box.selected svg rect {
    fill: rgba(0, 175, 133, 1);
}

.spot-map-completed .spot-box:hover svg rect,
.spot-box.spot-selected svg rect {
    fill: #084D3D !important;
}

.spot-box.mapped-spots svg rect {
    fill: rgb(2 106 81);
}

.pl-search-container {
    padding: 0 10px;
    justify-content: flex-end;
    display: flex;
}

.spot-box.fade,
.non-box.fade:not(.map-bg-image) {
    opacity: 0.2 !important;
    pointer-events: none;
}

.spot-box.redColor svg rect {
    fill: rgba(220, 38, 38, 0.3) !important;
}

.spot-box.redColor svg rect:hover, .spot-box.spot-selected.redColor svg rect {
    fill: #DC2626 !important;
}

.spot-box.greyColor svg rect {
    /* border: 1px dashed #323232; */
    stroke-width: 2;
    stroke-dasharray: 5, 5;
    -webkit-text-stroke-color: #323232;
}


.spot-box.greenColor svg rect {
    fill: #00AF85 !important;
}

.spot-box.greenColor svg rect:hover, .spot-box.spot-selected.greenColor svg rect {
    fill: #084D3D !important;
}

.spot-box.yellowColor svg rect {
    fill: #F7C10F !important;
}

.spot-box.yellowColor svg rect:hover, .spot-box.spot-selected.yellowColor svg rect {
    fill: #CE9F05 !important;
}

.spot-box.greyColor svg rect {
    fill: #8A959E !important;
}

.spot-box.greyColor svg rect:hover {
    fill: #6e7983 !important;
}

.spot-box.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.m-spot-zone {
    z-index: 2;
}

.spot-box {
    z-index: 3;
}

.m-spot-icons {
    z-index: 4;
}

.m-spot-text {
    z-index: 5;
}

.border-element {
    /* display: block !important; */
    /* width: 100px;
    height: 100px; */
    position: absolute;
    background: linear-gradient(to right, #00af85 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#00af85 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #00af85 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#00af85 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 20px 2px, 2px 20px;
    border: 0 dashed;
    z-index: 100;
}

.no-cursor svg rect {
    cursor: default !important;
}

.spot-box svg {
    vertical-align: top;
}

.sm-zoom-p-c {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding: 8px;
}

.map-bg-image, .non-box.fade.vector-bg-image {
    opacity: 1 !important;
}
