.zone-labeltext, .zone-add-button {
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    margin: 10px 0px;
}

.zone-add-button {
    padding-left: 15px;
    margin-bottom: 20px;
    /* font-weight: 500; */
}

.zone-add-button:hover {
    text-decoration: underline;
}

.zone-labeltext + .MuiFormControl-marginNormal {
    margin-top: 2px;
}

.zone-address {
    margin-left: 26px;
    text-indent: -27px !important;
    margin-bottom: 20px;
}

.bb-px-1{
    border-bottom: 1px solid darkgray;
}