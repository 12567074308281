.spot-detail-view, .spot-edit-view {
    width: 500px;
}

.spot-detail-view {
    overflow-y: auto;
}

.right-panel-title {
    line-height: 36px;
}

.spot-detail-view .rbc-time-content {
    overflow-y: visible !important;
}

.amenity-item > span {
    vertical-align: text-top;
}

.v-c {
    align-items: center;
}