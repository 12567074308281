.property-schedule {
    padding: 0px 10px 0px 0px;
}

.property-schedule h4 {
    display: none;
}

.filter-root {
    width: 98%;
}

.filter-heading {
    font-size: 15px !important;
    font-weight: bold !important;
}

.filter-panel {
    box-shadow: none !important;
}

.filter-panel:not(:first-child) {
    border-top: 1px solid #ccc;
    margin: 0px !important;
}

.filter-panel .MuiExpansionPanelSummary-root {
    padding: 0px !important;
}

.filter-panel .MuiCheckbox-root,
.filter-panel .MuiRadio-root {
    padding: 0 10px 0px 10px;
}

.filter-panel .MuiExpansionPanelDetails-root {
    padding: 0px;
    flex-direction: column;
}

.filter-panel .MuiFormControl-marginNormal {
    margin-top: 0px !important;
}

.slider-revenue-controller {
    padding: 30px 20px;
}

.slider-revenue-controller .MuiGrid-container {
    flex-wrap: nowrap;
}

.slider-value-grid {
    margin-top: 30px !important;
}

.revenue-lable-small {
    font-size: 15px;
    font-weight: bold;
    color: #b7b1b1;
}

.MuiGrid-root:last-child .revenue-lable-small {
    text-align: right;
}

.expand-details {
    color: #8a959e;
    cursor: pointer;
}

.property-overview-header {
    align-items: center;
}

.dashboard-box-container {
    margin: 10px 0px;
}

.dashboard-card {
    background: #ffffff;
    margin: 10px;
    width: calc(100% - 20px) !important;
    padding: 20px;
    border-radius: 5px;
    /* min-width: 300px; */
}

.card-title-container {
    align-items: center;
    display: flex;
}

.dashboard-card button {
    color: #00af85 !important;
    background: #f0faf8 !important;
    cursor: default;
    pointer-events: none;
}

.dashboard-card-title {
    font-size: 20px;
    margin-left: 15px;
    font-weight: bold;
}

.dashboard-card-title2 {
    font-size: 25px;
    font-weight: bold;
}

.dashboard-card-subtitle {
    font-size: 15px;
    font-weight: bold;
    color: #8a959e;
    display: block;
}

.dashboard-card-row2 {
    margin-top: 20px !important;
}

.dashboard-white-container {
    background: #ffffff;
    padding: 20px;
    margin: 10px 10px 20px 10px;
    border-radius: 5px;
}

.bar-char-container canvas {
    width: 100% !important;
    max-height: 400px;
}

.bar-chart-text {
    color: #8a959e;
}

.dashboard-tabs-container {
    margin: 10px 10px 20px 10px;
}

.dashboard-tabs-container .MuiTabs-root {
    background: #ffffff;
}

.dashboad-table {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

.without-checkbox-container th:first-child,
.without-checkbox-container td.table-list-cell:first-child,
.without-checkbox-container td.table-list-cell:last-child .dashboad-table th.data-grid-th:first-child,
.dashboad-table th.data-grid-th:last-child,
.dashboad-table td.table-list-cell:first-child,
.dashboad-table td.table-list-cell:last-child {
    padding: 13px 15px !important;
}


.view-spots {
    margin: 0px 10px 0px 0px;
}

.property-state {
    margin-right: 10px;
}

.list-header-container {
    align-items: center;
}

.table-right-menu .list-header-container .list-header {
    margin: 0px !important;
}

.hide-map {
    display: none;
}

.address-coloum {
    max-width: 200px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.close-menu-icon span.link-container {
    position: relative;
}

.close-menu-icon span.spot-count {
    position: absolute;
}

.close-menu-icon button {
    margin-left: 30px;
}

.disbursement-setting .MuiOutlinedInput-inputAdornedEnd {
    padding-right: 5px;
}

.property-schedule .repeat-on-label {
    display: none;
}

.property-schedule .repeat-on-label+div,
.list-header-time+div {
    margin-top: 0px !important;
}

/*.list-header-time {
    text-indent: -15px;
}*/

#property-link-txt {
    cursor: pointer;
    /* background: hsl(120, 100%, 93%); */
    background: rgb(243 243 243);
    border-color: #00C642;
    padding: 10px 10px 10px 34px;
    border: 1px #cccccc solid;
}

#property-link-txt span {
    padding-left: 5px;
}

.daily-hours-container {
    /* padding: 0px 30px; */
    padding: 0px 0px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.daily-hours-container.overlapping-error {
    border-color: #ff0000;
}

.daily-hours-error-msg {
    color: #ff0000;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: bold;
}

.daily-hours-container .MuiGrid-container {
    align-items: center;
}

.daily-hours-container .number-range-selector>label {
    display: none;
}

.daily-hours-container .number-range-selector>div {
    /* margin: 0px 0px 10px 36px !important; */
    margin: 0px 0px 0px 0px !important;
}

.daily-hours-container .number-range-selector>div>div:first-child,
.daily-hours-container .number-range-selector>div>span {
    display: none;
}

.daily-hours-container .number-range-selector .col {
    padding: 0px 5px;
}

.daily-hours-container .number-range-selector .txt-range-value {
    width: 90px !important;
}

.daily-booking-container label {
    margin: 0px;
}

.daily-booking-container {
    margin-bottom: 5px;
}

.daily-hours-container label.label-range-marker {
    margin: 0px 10px;
}

/* .daily-booking-container .m-0.row .col::before {
    content: '06:00AM';
} */

.monthly-description {
    padding: 0px 33px;
    margin-top: -15px;
}

.property-qr-con {
    display: flex;
    flex-direction: column;
    align-items: center;
}