.add-user-menu {
    padding-right: 0px !important;
    min-width: 500px;
    margin: 0px 10px;
}

.add-user-appbar {
    position: relative !important;
}

.add-user-appbar-title {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 10px;
    flex: 1;
    text-align: left;
}

.popup-body {
    padding: 10px;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.user-form-container {
    max-width: 500px;
    margin: 0px auto;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.form-sub-container {
    flex: 1;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    flex-basis: 0;
    padding-right: 10px;
}

.add-user-menu .rdrDateRangeWrapper {
    width: 100%;
}