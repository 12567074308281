.searchSection{
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: '#fff';
    /* flexDirection: 'row';
    justifyContent: 'center';
    alignItems: 'center';
    backgroundColor: '#fff'; */
}
.searchIcon {
    padding:10;
    height: 20px;
    width: 20px;
}
.input {
    flex: 1;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    background-color: #fff;
    color: #424242;
    /* paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#424242', */
}
.row-center{
    display: flex;
    flex-direction: row;
    width: '100%';
    justify-content: center;
    
}
.CenterView{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
 }
 .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 12px;
  }
  
  .item {
    height: 40px;
    width : 90px;
    background-color: #eee;
  }