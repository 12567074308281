.enforcement-page {
    display: flex;
    margin: 0 !important;
    overflow: auto;
}

.enforcement-body {
    flex: 1;
    display: flex;
    padding-bottom: 0;
}

.enforcement-rt-header-container {
    /* width: 400px !important; */
    /*padding-left: 277px;*/
    width: calc(100% - (100% - 300px)) !important;
}

.enforcement-parent-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
/*     padding-right: 0.5rem;
    padding-left: 0.5rem; */
    padding-top: 5px;
    border-radius: 10px;
}

.enforcement-parent-right {
    max-width: 400px;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    position: relative;
    overflow-y: auto;
}

.right-panel-subcontainer {
    position: absolute;
    width: 100%;
}

.notify-toaster-pl,
.notify-toaster-pl > .MuiSnackbarContent-root {
    width: 315px;
    height: 160px;
    top: 196px !important;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #323232;
    text-align: center;
    right: 40px !important;
}

.notify-toaster-pl {
    z-index: 9 !important;
}


.notify-toaster-pl > .MuiSnackbarContent-root > .MuiSnackbarContent-message {
    flex: 1;
}

.enforcement-right-panal {
    width: 366px !important;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.details-tenant-name {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #323232;
}

.details-tenant-email {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #111012;
}

.padd-tenant-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    padding-bottom: 20px;
}

.padd-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #323232;
}

.padd-tenant-exisiting-msg {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #323232;
}

.remove-access-item {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF4242 !important;
}

.add-tenant-pl-panel-body {
    padding: 0 20px 10px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.add-tenant-block-pl:first-child {
    margin-top: 10px;
}

.add-tenant-block-pl:last-child {
    margin-bottom: 10px;
}

.add-tenant-block-pl .MuiFormControl-marginNormal {
    margin-top: 5px;
}

.panel-body-field-label {
    font-size: 14px;
    font-weight: 700;
    color: #323232;
    margin: 15px 0 5px 0;
    text-align: left !important;
}

.add-tenant-subheading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #6B7278;
}

.bulk-upload-blocker {
    position: fixed;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1202;
}

.bulk-upload-popup {
    background: #ffffff;
    padding: 40px;
    display: flex;
    border-radius: 10px;
}

.bulk-upload-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.0324em;
    color: #111012;
}

.bulk-upload-sub-content {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #323232;
}

.bul-upload-primary-btn {
    width: 131px;
    height: 42px;
    border-radius: 10px;
}


.link-color {
    font-size: 15px !important;
    text-decoration: underline !important;
    color: #00AF85 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

/* .file-dropbox > .file-drop-target {
    position: relative;
    height: 100%;
    border: 1px dashed #cccccc;
}

.file-dropbox > .file-drop-target.file-drop-dragging-over-frame {
    border-color: #000000;
}

.file-dropbox > .file-drop-target.file-drop-dragging-over-target {
    border-color: #00af85;
} */

/* .bulk-upload-file-container {
    box-sizing: border-box;
    width: 340px;
    height: 177px;
    border: 1px dashed #8A959E;
} */
.bulk-upload-file-container {
    width: 90%;
}

.bulk-upload-file-container .file-dropbox {
    min-height: 100px;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target {
    position: relative;
    height: 200px;
    border: 1px dashed #8A959E;
    display: flex;
    flex: 1;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target.file-drop-dragging-over-frame {
    border-color: #000000;
}

.bulk-upload-file-container .file-dropbox > .file-drop-target.file-drop-dragging-over-target {
    border-color: #00af85;
}

.bulk-upload-file-container .dropbox-content {
    /* width: 340px; */
    height: 177px;
    flex: 1;
}

.multi-remove-access-data {
    border-bottom: 1px solid #F0F0F0;
}

.enforcement-selected-properties {
    white-space: nowrap;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}

.drawer-panel-description {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #323232;
}

.header-p-border-bottom {
    border-bottom: 1px solid #F8F8F8;
}

.header-p-border-top {
    border-top: 1px solid #F8F8F8;
}

.pl-more-details,
.pl-more-details:hover,
.try-again {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    text-decoration: underline !important;
    color: #00AF85 !important;
    background-color: #fff !important;
    cursor: pointer;
}

.pl-show-more,
.pl-show-more:hover {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    color: #00AF85 !important;
    background-color: #fff !important;
    cursor: pointer;
}

.override-btn {
    width: 380px;
    margin: auto;
}

.add-tenant-container {
    text-align: right;
}

.add-tenant,
.remove-tenant {
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    color: #00AF85;
}

.remove-tenant {
    color: #DF4242;
}

.del-box-right {
    position: relative;
    min-height: 20px;
    border-top: 2px solid #6B7278;
    margin-top: 20px;
    padding-top: 5px;
}

.del-box-right > div {
    position: absolute;
    right: 0;
}

.secure-lf-header-container.t-view {
    padding-top: 0;
    padding-bottom: 7px;
}

.multi-select-blocker {
    background: #F5F5F5;
    opacity: 0.6;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.file-picker-container {
    margin: auto;
    position: relative;
    height: 198px !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.file-picker-container.container-padding {
    padding: 85px 0 85px 0;
}

.file-picker-container button {
    width: 140px;
}

.file-picker-container span {
    text-decoration: underline;
    cursor: pointer;
}

.common-toaster-pl,
.common-toaster-pl > .MuiSnackbarContent-root {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    background: #00AF85;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* width: 353px; */
    height: 51px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    text-decoration: underline !important;
}

.privatelotPages {
    width: 303px;
    padding: 0 0 0 0 !important;
    height: 46px;
    border-radius: 10px !important;
    margin-top: 0.4rem !important;
}

.InputAdornment svg {
    margin: 0 0 2px 12px;
}

.privatelotPages input {
    padding-top: 4px !important;
}

.privatelotPages button {
    padding-top: 10px;
}

.privatelotPages:hover {
    border: 1px solid #000000;
}

.privatelotPagesFocus {
    border: 2px solid #00b188 !important;
}

.removeButton {
    width: 50px !important;
    padding: 0 !important;
}

.removeWhileBg label {
    padding-right: 10px !important;
    background: none !important;
}

.offence-text {
    color: #DC2626;
    font-size: 1.15rem;
}

.padd-enforcement-item {
    /*font-weight: 700;*/
    padding: 0 20px 0 20px;
    margin: 0;
    font-size: 1rem !important;
}

.first-item {
    border-bottom: 1px solid lightgray;
    margin: 10px 10px 10px 10px;
}

.scanhistory-item {
    padding: 0 20px 0 20px;
    margin: 10px 0 10px 0 !important;
}

.fw {
    font-weight: 700;
}

.last-item {
    border-bottom: 1px solid lightgray;
    margin: 10px 10px 10px 10px;
}

.scanItem-container {
    border: 1px solid lightgray;
    border-left: 0;
    border-right: 0;
}

.bb-px-0 {
    border-bottom: 0 !important;
}

div.scanItem-container:hover {
    background-color: #F8F8F8;
}

.scan-item-container {
    padding: 0 0 0 18px;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.scanHistory-panel .MuiDrawer-paperAnchorRight {
    width: 450px !important;
}

.padd-scanhistory-heading {
    font-size: 24px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0;
}

.p-mt {
    padding: 5px !important;
    margin-top: 8px !important;
}

.offence-detail-text {
    color: #DC2626;
}

.anchorRemoveUnderline {
    color: #00b188 !important;
}

a.anchorRemoveUnderline:hover {
    text-decoration: none !important;
    color: rgb(0, 123, 95) !important;
}

.padd-enforcement-heading {
    font-weight: 700;
}

.padd-enforcement-heading-scan-active {
    border-bottom: 1px solid darkgray;
    width: 100%;
}

.padd-enforcement-heading-tow-active {
    border-bottom: 2px solid;
    margin: 0 41px 0 14px;
}

.padd-enforcement-tow-heading {
    font-weight: 700;
}

.img-size {
    width: 16px;
}

.enforcement-active {
    background-color: #FFFFFF !important;
    color: #00AF85 !important;
    outline: #00AF85 solid 1px !important;
    padding: 20px 10px 20px 10px !important;
}

.pl-px-17 {
    padding-left: 17px;
}

.secure-notification-sub-body {
    list-style: none;
}

li.pt-2::before {
    content: "\2022";
    color: #00b188;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 20px;
}

.notification-no-border {
    border-bottom: none !important;
}

.scanItem-navbar:hover {
    cursor: pointer;
}
