.login-page {
    flex-direction: row !important;
    display: flex;
    flex: 1;
    /* height: 100vh; */
}

.login-banner {
    background: url('../Images/login-banner.jpg') no-repeat;
    background-size: cover;
    background-position: right;
    flex: 1;
}

.login-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 20px;
    min-width: 400px;
    margin: auto;
    max-width: 400px;
    border-radius: 5px;
    overflow: auto;
}

.signInMessage {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.input,
.input label,
.input input,
.input .spin,
.button,
.button button {
    width: 100%;
    float: left;
}

.input {
    margin-top: 10px;
    height: 50px;
    display: flex;
}

.username-box {
    /* margin-top: 0px; */
    margin: 10px 0px;
}

.username-box input[type="number"] {
    -moz-appearance: textfield;
}

.username-box input[type="number"]::-webkit-outer-spin-button,
.username-box input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input,
.input input {
    position: relative;
}

.input input {
    height: 40px;
    border: none;
    background: transparent;
    outline: 0;
    font-size: 18px;
    border: 2px solid #ccc;
    padding: 5px 10px;
    flex: 1;
}

.input input:focus {
    border: 2px solid #00b089;
}

.input input.error {
    border: 2px solid #ff0000;
    box-shadow: 0 5px 5px -5px #ff0000;
}

.login-container .error-msg {
    /* position: absolute; */
    bottom: 0;
    margin: 10px 0px;
    color: #ff0000;
    font-size: 18px;
}

.body-container.alpha .motion-button {
    margin: 30px auto 40px auto;
}

.login-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.motion-button-login {
    background: #ffffff;
    color: #00b089;
    border: 2px solid #00b089;
    /* text-transform: uppercase; */
    font-size: 20px;
    margin: 20px auto;
    border-radius: 5px;
    display: block;
    padding: 10px;
    cursor: pointer;
    min-width: 200px;
    width: 100%;
    font-family: "Helvetica Condensed";
    outline: 0;
    text-transform: uppercase;
}

.motion-button-login:hover {
    background: #00b089;
    color: #FFFFFF;
}

button:disabled {
    color: graytext !important;
    background-color: buttonface !important;
    border-color: buttonface !important;
}

.hide {
    display: none;
}

.EditButton {
    cursor: pointer;
}

.highlight {
    color: #008CBA;
}

.resend {
    color: #00b089;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.eyePos {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

@media only screen and (max-height: 550px) {
    .login-container {
        display: block;
        overflow: auto;
    }
}

@media only screen and (max-width: 550px) {
    .register-page .paper {
        max-width: 400px !important;
        width: 90%;
    }

    .Beacon .BeaconContainer,
    .BeaconFabButtonFrame {
        left: 10px;
    }

    .hide-beacon-mobile #beacon-container {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .App.login-page {
        flex-direction: column !important;
    }

    .login-container {
        margin: auto;
    }
}

@media only screen and (max-height: 550px),
(max-width: 1023px) {
    .login-banner {
        display: none;
    }

    .App.login-page {
        display: flex;
        overflow: auto;
    }

    .login-container {
        overflow: visible;
        margin: auto;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
}

@media only screen and (max-width: 500px) {
    .login-banner {
        display: none !important;
    }

    .login-container {
        margin: 0px !important;
        min-width: inherit !important;
        max-width: inherit !important;
    }
}

/* .root {
    height: '100vh';
} */

.register-form-container {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

/* .image {
    background-image: url('../Images/login-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} */

.register-page .paper {
    margin: 0px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    flex: 1;
    width: 100%;
    flex-basis: 0;
}

.avatar {
    margin: 1;
    background-color: #ffffff;
}

form {
    width: 100%;
    margin-top: 1px,
}

.submit {
    margin: 3 0 2;
}

.sigin-link {
    margin: 10px 0px !important;
    display: block;
}

.react-tel-input .form-control {
    width: inherit !important;
    height: 56px;
}

.react-tel-input .form-control+div:before {
    left: 10px !important;
    content: 'Phone' !important;
}

.react-tel-input.Mui-disabled .form-control+div:before {
    color: rgba(0, 0, 0, 0.38);
}

.react-tel-input .form-control:focus {
    border-color: #00b188 !important;
    box-shadow: 0 0 0 1px #00b188 !important;
}

.react-tel-input .form-control:focus+div:before {
    color: #00b188 !important;
}

.react-tel-input .form-control.invalid-number:focus {
    box-shadow: 0 0 0 1px #f44336 !important;
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid #f44336 !important;
}

/* 
.logo {
    max-width: 200px;
} */

.register-button {
    margin-top: 16px !important;
    background: #00b188 !important;
}


.register-button .MuiButton-label {
    padding: 5px;
}

.react-tel-input input.form-control:disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: default;
    border: 1px solid #CACACA !important;
}

.otp-messsage {
    font-size: 18px;
    text-align: center;
}

.otp-messsage.otp-small {
    font-size: 14px;
}

.otp-messsage .bold {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.password-help {
    text-align: right;
    color: #00AF85;
}

.password-help svg {
    cursor: pointer;
}

/*Wizard Style start here*/
.wizard-title {
    margin: 10px 0px;
}

.wizard__steps {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(50%);
    z-index: 10;
    margin-bottom: 20px;
}

.wizard__footer {
    padding: 0 50px 50px;
    border-radius: 5px;
}

.wizard__content {
    background: #fff;
    box-shadow: 0px 0px 10px #c5c5c5;
    border-radius: 5px;
}

.wizard__congrats-message {
    color: #676767;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.wizard.completed .wizard__content {
    animation: gettingOut 2s cubic-bezier(1, -0.71, 1, 1.16) forwards;
}

.wizard.completed .wizard__congrats-message {
    animation: fadeIn 2s cubic-bezier(1, -0.71, 1, 1.16) forwards;
}

.line {
    position: absolute;
    top: 17px;
    left: 50%;
    z-index: -1;
    height: 6px;
    transition: all 0.5s ease;
}

.line.-start {
    left: 0%;
    background: #00AF85;
    width: 50%;
}

.line.-end {
    left: 50%;
    background: #00AF85;
    width: 50%;
}

.line.-background {
    background: #c3c3c3;
    width: 100%;
}

.line.-progress {
    background: #00AF85;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left center;
}

.line.-in-progress {
    transform: scaleX(1);
}

.panels {
    position: relative;
    overflow: hidden;
}

.panel {
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s all;
    padding: 50px;
}

.panel__header {
    margin-bottom: 30px;
}

.panel__title {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
}

.panel__subheading {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin: 0;
}

.panel.movingOutBackward {
    transform: translateX(-620px);
}

.panel.movingOutFoward {
    transform: translateX(620px);
}

.panel.movingIn {
    transform: translateX(0);
}

.steps {
    position: relative;
    display: flex;
    flex: 0 1 auto;
    color: #fff;
}

.step {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
}

.step__content {
    position: relative;
    z-index: 2;
}

.step__number {
    font-size: 1.3rem;
    color: #676767;
    background: #fff;
    font-weight: bold;
    width: 40px;
    height: 40px;
    line-height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    border: 5px solid #c3c3c3;
    transition: opacity 0.5s;
    opacity: 1;
    z-index: 5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step.-wip .step__number {
    border-color: #00AF85;
}

.step.-completed .step__number {
    opacity: 0;
}

.step.-completed .checkmark {
    z-index: 0;
    animation: fill 0.4s ease-in-out forwards, scale 0.3s ease-in-out 0.6s both;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step.-completed .checkmark__check {
    animation: stroke 0.5s linear 0.4s forwards;
}

.step.-completed .line {
    transform: scaleX(1);
}

.step:last-child .line {
    width: 50%;
}

.step-info {
    display: none;
    color: #000000;
}

.checkmark {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    line-height: 48px;
    border-radius: 50%;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #00AF85;
    z-index: -1;
    cursor: pointer;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 8;
    stroke-miterlimit: 10;
    stroke: #00AF85;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    stroke: #fff;
    stroke-width: 5;
}

.button {
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    background: #00AF85;
    color: #fff;
    padding: 10px 15px;
    border: none;
    outline: none;
    display: inline-block;
    transition: all 0.3s;
}

.button:hover {
    background: #7baee6;
}

.button.previous {
    margin-right: 5px;
}

.button.disabled {
    background: #c3c3c3;
    cursor: default;
}

@keyframes stroke {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    50% {
        transform: translateX(-50%) scale3d(1.5, 1.5, 1.5);
    }

    100% {
        transform: scale3d(0);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #00AF85;
    }
}

@keyframes gettingOut {
    0% {
        transform: translateY(0%);
    }

    30% {
        transform: translateY(100px);
    }

    100% {
        transform: translateY(-200%);
    }
}

@keyframes fadeIn {
    100% {
        opacity: 1;
    }
}

/*Wizard Style end here*/

.App.register-page {
    overflow: hidden;
}

.register-form-parent-container {
    flex: 1;
    display: flex;
    width: 100%;
    flex-basis: 0;
}

.register-form-parent-container form {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.form-sub {
    flex: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 5px;
}

.register-info {
    padding: 10px;
    margin-top: 10px;
    background: rgb(243 243 243);
}