.dashboard-widget-container {
    padding: 0px 0px 50px 0px;
}

.m-3.main-dashboard {
    margin-top: 0px !important;
}

.main-dashboard>.mb-3 {
    margin-bottom: 0px !important;
}

.dashboard-widget {
    margin: 10px 20px 10px 0px;
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    min-width: 270px;
    border: 1px solid #c1c7cc;
    position: relative;
    overflow: hidden;
    height: 103px;
}

.non-admin-board {
    height: 103px;
    overflow: visible;
    min-width: 550px;
}

/*.MuiGrid-item:first-child .dashboard-widget {
    margin-left: 0px;
}

.MuiGrid-item:last-child .dashboard-widget {
    margin-right: 0px;
}*/

.dashboard-widget-value {
    /* color: #818082; */
    color: #000000;
    font-size: 25px;
    font-weight: bold;
}

.dashboard-widget-title {
    /* color: #c1c7cc; */
    /* color: #818082; */
    color: #000000;
    font-size: 15px;
    /* text-transform: uppercase; */
    font-weight: bold;
    padding-right: 20px;
}

.dashboard-widget-sub-title {
    font-size: 12px;
    font-weight: bold;
    color: #c1c7cc;
}

.dashboard-widget-arrow {
    /* vertical-align: super; */
    color: #78d6c0;
}

.dashboard-widget-arrow svg {
    vertical-align: super;
}

.dashboard-widget-arrow .arrow-upward {
    color: #00b88d;
}

.dashboard-widget-arrow .arrow-downward {
    color: #e68686;
}

.dashboard-widget-percent {
    font-size: 15px;
    font-weight: 700;
    color: #c1c7cc;
}

.margin-left {
    margin-left: 5px;
}

.dashboard-widget-chart-conainer {
    margin: 0px 0px;
}

.dashboard-widget-chart {
    position: relative;
    background: #fff;
    padding: 10px;
    width: calc(100% - 20px);
    border: 1px solid #c1c7cc;
    margin: 10px 0px;
    border-radius: 10px;
}

.dashboard-widget-chart h5 {
    /* color: #818082; */
    color: #000000;
    font-size: 15px;
    font-weight: bold;
}

.dashboard-widget-chart.selected h5 {
    color: #ffffff !important;
}

.export-reservation {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.export-reservation:hover {
    text-decoration: underline;
}

.dashboard-select {
    padding: 0px 10px;
    background: #00b88d !important;
    border-radius: 5px;
    color: #ffffff !important;
    font-size: 12px !important;
}

.dashboard-select .MuiSelect-root {
    height: auto !important;
    line-height: 26px;
    padding: 0px 20px 0px 0px;
}

.dashboard-select svg {
    color: #ffffff;
}

.dashboard-widget-row {
    margin-top: 0px;
    align-items: center;
    min-height: 55px;
}

.dashboard-widget-cancelled {
    color: #ff9f1d;
    padding-left: 20px;
}

.cancel-percentage {
    font-size: 25px;
    font-weight: bold;
}

.cancel-text {
    font-weight: bold;
    font-size: 12px;
}

/* .dashboard-widget:hover, */
.dashboard-widget.selected,
.dashboard-widget-chart.selected {
    background: #00b88d;
}

.dashboard-widget.selected .dashboard-widget-title,
.dashboard-widget.selected .dashboard-widget-value,
.dashboard-widget.selected .dashboard-widget-percent,
.dashboard-widget.selected .dashboard-widget-arrow svg {
    color: #ffffff;
}

.dashboard-widget-chart.selected .dashboard-select,
.dashboard-widget.selected .dashboard-select {
    background: #ffffff !important;
    color: #00b88d !important;
}

.dashboard-widget-chart.selected .dashboard-select svg,
.dashboard-widget.selected .dashboard-select svg {
    color: #00b88d;
}

.dashboard-widget-chart .table-list-cell {
    padding: 10px 5px !important;
}

.dashboard-widget-chart .table-list-cell:nth-child(1) {
    width: 25px !important;
}

.dashboard-widget-chart .table-list-cell:nth-child(2) {
    width: 10px !important;
}

.dashboard-widget-chart.selected .table-lis-footer {
    display: none !important;
}

.dashboard-widget-chart.selected .Pagination .MuiPaginationItem-page {
    display: none;
}

.dashboard-widget-chart.selected .Pagination li:nth-child(3) .MuiPaginationItem-page,
.dashboard-widget-chart.selected .Pagination li:nth-child(4) .MuiPaginationItem-page {
    display: block !important;
    color: #ffffff !important;
}

.dashboard-widget-chart .Pagination li .MuiButtonBase-root {
    width: 22px !important;
    height: 22px !important;
    margin: 0px !important;
}

.dashboard-widget-chart .MuiPaginationItem-page.Mui-selected,
.dashboard-widget-chart .MuiPaginationItem-root:hover {
    background: none !important;
    font-weight: bold;
}

.dashboard-widget-chart.grid-white-bg .MuiPaginationItem-page {
    color: #7cccb9 !important;
}

.dashboard-widget-chart.grid-white-bg .MuiPaginationItem-page.Mui-selected,
.dashboard-widget-chart.grid-white-bg .MuiPaginationItem-root:hover {
    color: #00b88d !important;
}


.dashboard-widget-chart .data-grid-table-container {
    min-height: 270px !important;
}

.dashboard-widget-chart.selected .data-grid-th {
    background-color: #00b88d !important;
    color: #ffffff !important;
}

.dashboard-widget-chart.selected .MuiTableSortLabel-root:hover {
    color: #ffffff !important;
}

.dashboard-widget-chart.selected .MuiTableCell-root {
    border-bottom: 1px solid #ffffff !important;
    background-color: #00b88d !important;
    color: #ffffff !important;
}

.dashboard-widget-chart .MuiTableCell-root {
    font-size: 14px !important;
}

.dashboard-widget-chart.selected .MuiTableRow-root:hover .MuiTableCell-root {
    font-weight: bold;
}

.dashboard-widget-chart .MuiPaper-root {
    box-shadow: none;
}

.dashboard-widget-chart.selected .MuiPaper-root {
    background-color: #00b88d;
}

.listPropertyCities {
    width: 300px !important;
    background: #00b88d !important;
    margin-top: 10px !important;
    margin-right: 20px !important;
}

.listPropertyCities .MuiOutlinedInput-notchedOutline {
    border: 0px !important;
}

.listPropertyCities .MuiOutlinedInput-notchedOutline span {
    background: none !important;
}

.listPropertyCities .MuiFormLabel-root {
    background: #00b88d !important;
    color: #ffffff !important;
}

.listPropertyCities .MuiAutocomplete-clearIndicator,
.listPropertyCities .MuiAutocomplete-popupIndicator {
    color: #ffffff !important;
}

.listPropertyCities .MuiChip-root {
    height: 24px !important;
    margin: 2px;
}

.listPropertyCities .MuiChip-root .MuiChip-label {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.dashboard-widget-chart img {
    width: 100%;
    min-width: 250px;
}

.coming-soon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    border-radius: 8px;
}

.pm-name {
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
}

.dashbaord-welcome {
    color: #ffffff;
}

.vehicle-img-container {
    position: relative;
    width: 160px;
}

.vehicle-img-container img {
    width: 160px;
    position: absolute;
    left: 0px;
    top: -55px;
}

.hide-chip {
    display: none !important;
}

@media only screen and (max-width: 1000px) {
    .vehicle-img-container {
        position: relative;
        width: 120px;
        text-align: right;
    }

    .vehicle-img-container img {
        width: 100px;
        position: relative;
        left: 0px;
        top: 0px;
    }
}

.w-module-flex {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.w-module-flex.w-row {
    flex-direction: row;
    align-items: baseline;
    position: relative;
}

.w-module-cell.xs {
    flex: 1;
}

.blur-box {
    /* -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px); */
    
    position: absolute;
    width: calc(100% - 40px);
    height: 50px;
    margin: auto;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    bottom: 0px;
    padding: 5px;
}

.dashboard-widget-v2 {
    flex: 1;
    flex-wrap: wrap;
    padding: 0px 20px;
    background: #fff;
    min-height: 105px;
    display: flex;
    margin: 20px 0px;
    position: relative;
}

.dashboard-widget-v2:not(:last-child) {
    border-right: 2px solid #F0F0F0;
}

.w-m-title {
    color: #323232;
    font-weight: 700;
    size: 12px;
    line-height: 17px;
    flex: 1;
}

.w-m-value {
    font-size: 30px;
    line-height: 41px;
    font-weight: 700;
}

.w-mt {
    margin-top: 10px;
}

.w-m-gvalue {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
}

.w-m-gvalue.positive {
    color: #00AF85;
}

.w-m-gvalue.negative {
    color: #DC2626;
}

.w-module-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #0000000A;
    box-shadow: 0px 2px 6px 0px #0000000A;
    box-shadow: 0px 10px 20px 0px #0000000A;
}

.w-d-header {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #323232;
}

.w-d-spot-count {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.w-d-spot-status {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.w-d-spot-status b {
    font-weight: 700;
    font-size: 16px;
}

.w-m-table {
    display: flex;
    flex-direction: row;
    position: relative;
}

.w-m-top {
    margin-top: 15px !important;
}

.w-m-padding {
    padding: 15px;
}

.w-m-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #0000000A;
    box-shadow: 0px 2px 6px 0px #0000000A;
    box-shadow: 0px 10px 20px 0px #0000000A;
    margin-right: 15px;
    padding: 15px;
}

.w-module-p-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px #0000000A;
    box-shadow: 0px 2px 6px 0px #0000000A;
    box-shadow: 0px 10px 20px 0px #0000000A;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.w-m-cell.w-m-xs {
    flex: 1;
}

.w-m-full {
    flex: 1;
}

.w-m-center {
    align-items: center;
}

.w-m-round {
    width: 16px;
    height: 16px;
    background: #00AF85;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 6px;
}

.w-m-round.w-m-dim {
    background: rgba(0, 175, 133, 0.1);
}

.w-m-wrap {
    flex-wrap: wrap;
}

.dashboard-widget-v2.w-m-box {
    margin: 0px;
}

.w-min-width {
    min-width: 250px;
}

.w-m-cell canvas {
    min-height: 160px;
    min-width: 160px;
    pointer-events: none;
}

/* .w-c-widget {

} */

/* .w-d-min-width {
    min-width: 150px;
} */

.w-module-p-container .w-m-unauth:not(:last-child) {
    margin-right: 15px;
}

.w-m-unauth {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    flex: 1;
    margin-bottom: 15px;
    min-width: 230px;
}

.w-m-unauth-s-body {
    display: block;
}

.gryd-green {
    background: #00AF85;
    color: #ffffff;
}

.gryd-yellow {
    background: #F7C10F;
}

.gryd-green2 {
    background: #084D3D;
    color: #ffffff;
}

.gryd-green50 {
    background: rgba(0, 175, 133, 0.5);
}


.w-unauth-title {
    font-weight: 400;
    size: 16px;
    line-height: 21px;
    white-space: nowrap;
}

.w-unauth-value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.w-city-dropdown {
    min-width: 300px;
    margin-bottom: 20px;
    margin-right: 30px;
}

.c-d-date-pick .cl-block-input input {
    padding: 11px 35px 11px 40px;
}

.c-d-date-pick .cl-block-input-c .cl-icon {
    top: 12px;
    left: 12px;
    color: rgba(0, 175, 133, 1);
    font-size: 18px;
}

.c-d-date-pick .cl-date-clear {
    top: 5px;
}

.c-d-date-pick .cl-block-range-calendar {
    right: inherit !important;
}

.c-d-date-pick .gryd-cl-view {
    min-width: 330px;
}

.w-info {
    color: #323232;
    font-size: 18px !important;
    cursor: pointer;
}

.w-info svg{
    color: #8A959E;
}

.w-info:hover .w-info-popup {
    display: block;
} 

.w-info-popup {
    display: none;
    position: absolute;
    background: #F0F0F0;
    max-width: 309px;
    border-radius: 4px;
    padding: 16px;
    right: 0px;
    width: 100%;
    z-index: 1;
    cursor: default;
    font-size: 14px;
    font-weight: 400;
}

.flexbasis{
    flex-basis: 32%!important;
}