.range-selector {
    min-width: 280px; 
}

.label-range-marker {
    line-height: 1px !important;
    font-size: 12px !important;
    color: #919ca4 !important;
}

.txt-range-value {
    border: 1px solid #919ca4;
    border-radius: 4px;
    width: 100px;
    padding: 8px;
    text-transform: capitalize;
}