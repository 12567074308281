.permission.m-3{
    margin: .1rem 0 0 0!important;
}

.search.data-grid-paper-container{
    margin-bottom: 25px;
    flex: none;
}

.permission-header-container{
    background-color: #fafafa;
    min-height: 63px;
    border-bottom: 2px solid rgba(224, 224, 224, 1);;
}

.permission .h3{
    padding: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px !important;
    letter-spacing: 0 !important;
    font-weight: bold !important;
    text-transform: uppercase;
    color: #8a959e !important;
    margin: 12px 0px 0px 15px;
}

.permission .items{
    padding: 10px;
}

.search-user{
    padding: 5px;
    margin-top: 0px!important;
}

.search-user-container{
    padding: 0 0 0 40px;
}

.search-user-container{
    margin-bottom: 10px!important;
}

.search-container{
    padding: 0 0 0 40px;
}

.search-roles{
    margin-bottom: 8px!important;
}

.search-roles .MuiAutocomplete-clearIndicator{
    display: none;
}

.search-user input{ 
    margin-top: 1px!important;
}

.userRole input{
    height: 4px;
}
.userRole .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
}

.search-btn button{
    height: 34px!important;
}

.rct-title{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px !important;
    letter-spacing: 0 !important;
    font-weight: 510;
    color: rgba(0, 0, 0, .87);
    margin-top: 10px;
}

.tree-container{
    padding: 10px 0 0 15px;
}

.radio-buttons-group .Mui-checked{
    color: #00b188!important;
}

.search-error{
    color: red;
    font-weight: 450;
    padding: 18px 0 0 0px;
}

.icon-text{
    margin-top: 2px!important;
}