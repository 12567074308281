.save-permission-container {
    text-align: right;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.permission-help {
    color: #00AF85;
}

.permission-help svg {
    cursor: pointer;
}