.dashboard-tile {
    text-align: center;
    height: 100%;
    display: block;
}

.no-dec-link {
    min-width: 180px;
}

.no-dec-link, .no-dec-link:hover {
    text-decoration: none;
    color: inherit;
}

.dashboard-tile .tile-heading {
    background-color: rgba(0,0,0,0.1);
    line-height: 2em;
}

.dashboard-tile .tile-content {
    font-size: 42px;
    line-height: 100px;
}

.dashboard-tile .tile-content-small {
    padding: 8px;
}

.bg-green {
    background-color: #00BF6F33;
}

.bg-yellow {
    background-color: #FFC84533;
}

.bg-blue {
    background-color: #6E6FBE33;
}

.bg-lightgrey {
    background-color: #e4eae0;
}

.bg-grey {
    background-color: #343a40;
}