/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;523;600;700;800&display=swap');

/* @font-face {
  font-family: "Helvetica";
  src: url(fonts/Helvetica_Light.eot#iefix);
  src: url(fonts/Helvetica_Light.eot#iefix) format("eot"), url(fonts/Helvetica_Light.woff2) format("woff2"), url(fonts/Helvetica_Light.woff) format("woff"), url(fonts/Helvetica_Light.ttf) format("truetype"), url(fonts/Helvetica_Light.svg#92c941ea-2b06-4b72-9165-17476d424d6c) format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url(fonts/Helvetica_Light_Italic.eot#iefix);
  src: url(fonts/Helvetica_Light_Italic.eot#iefix) format("eot"), url(fonts/Helvetica_Light_Italic.woff2) format("woff2"), url(fonts/Helvetica_Light_Italic.woff) format("woff"), url(fonts/Helvetica_Light_Italic.ttf) format("truetype"), url(fonts/Helvetica_Light_Italic.svg#bc7eeacf-e01a-4c89-bcf1-437b146ecf6d) format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url(fonts/Helvetica_Regular.eot#iefix);
  src: url(fonts/Helvetica_Regular.eot#iefix) format("eot"), url(fonts/Helvetica_Regular.woff2) format("woff2"), url(fonts/Helvetica_Regular.woff) format("woff"), url(fonts/Helvetica_Regular.ttf) format("truetype"), url(fonts/Helvetica_Regular.svg#66cac56e-d017-4544-9d0c-f7d978f0c5c2) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url(fonts/Helvetica_Bold.eot#iefix);
  src: url(fonts/Helvetica_Bold.eot#iefix) format("eot"), url(fonts/Helvetica_Bold.woff2) format("woff2"), url(fonts/Helvetica_Bold.woff) format("woff"), url(fonts/Helvetica_Bold.ttf) format("truetype"), url(fonts/Helvetica_Bold.svg#375c70e5-6822-492b-8408-7cd350440af7) format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url(fonts/Helvetica_Bold_Italic.eot#iefix);
  src: url(fonts/Helvetica_Bold_Italic.eot#iefix) format("eot"), url(fonts/Helvetica_Bold_Italic.woff2) format("woff2"), url(fonts/Helvetica_Bold_Italic.woff) format("woff"), url(fonts/Helvetica_Bold_Italic.ttf) format("truetype"), url(fonts/Helvetica_Bold_Italic.svg#25bfaeec-398f-41e5-9e64-9971dba5a437) format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Condensed";
  src: url(fonts/Helvetica_Condensed.eot#iefix);
  src: url(fonts/Helvetica_Condensed.eot#iefix) format("eot"), url(fonts/Helvetica_Condensed.woff2) format("woff2"), url(fonts/Helvetica_Condensed.woff) format("woff"), url(fonts/Helvetica_Condensed.ttf) format("truetype"), url(fonts/Helvetica_Condensed.svg#daea9df1-f3c1-4cbe-9302-636114bf4f38) format("svg");
  font-weight: bold;
  font-style: normal;
} */

* {
  scrollbar-color: #7f7f7f transparent !important;
  scrollbar-width: thin !important;
}

body {
  font-size: 15px !important;
  /* font-family: "Helvetica" !important; */
  /* font-family: 'IBM Plex Mono', monospace !important; */
  font-family: 'Manrope', sans-serif;
  line-height: normal;
  /* font-weight: 300; */
  color: #333333;
  background: #f8f8f8 !important;
  min-width: 320px;
}

section,
header,
nav,
div,
input,
a,
label,
td,
th,
button,
h1,
h2,
h3,
h4,
h5 {
  /* font-family: 'IBM Plex Mono', monospace !important; */
  font-family: 'Manrope', sans-serif;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header {
  /* display: flex;
  align-items: center; */
  background-color: #f8f8f8 !important;
  box-shadow: none !important;
  border: 0px !important;
}

header div.header-container-sub {
  flex: 1;
  text-align: right;
}

button:focus {
  outline: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Loading screen start*/

.blocker,
#spinnerScreen {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*background: url(../images/spinner.gif) no-repeat center, rgba(0,0,0,0.5);*/
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
}

#parentView {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  min-height: 50px;
  max-width: 438px;
  min-width: 230px;
  height: auto;
  margin: auto;
  border: 0px solid #000;
  font-weight: bold;
}

#anim {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 50px;
  height: 50px;
  margin: auto;
  background: url(./Images/transparent-loading-spinner-gif.png) no-repeat center;
  background-size: contain;
  -webkit-animation: myfirst 2s linear 0s infinite;
}

/* .show-message,
.show-blocker~#spinnerScreen, */
#spinnerScreen {
  display: flex;
  align-items: center;
}

@-webkit-keyframes myfirst {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}


/*Loading screen page end*/

.MuiTableCell-root {
  font-size: 13px !important;
  letter-spacing: 0 !important;
}

.MuiIconButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}

/*Left Navigation*/

.app-version {
  text-align: right;
  font-size: 12px;
  padding: 0px 30px 10px 30px;
}

.min-nav .app-version {
  text-align: center !important;
  padding-bottom: 10px !important;
}

.left-side-menu {
  /* background-color: #ebebeb; */
  background: #ffffff;
  border-right: 1px solid #CCCCCC;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  width: 350px;
  position: relative;
}

.left-side-menu.hide-menu {
  margin-left: -250px;
}

.toggle-menu {
  position: absolute;
  top: 80px;
  cursor: pointer;
  right: -10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* border: 2px solid #000000; */
}

.toggle-icon {
  background: #00af85 !important;
  color: #ffffff;
  padding: 3px !important;
  overflow: hidden !important;
}

.toggle-menu:hover,
.toggle-menu:active {
  background: #ebecec;
}

.left-side-menu .logo {
  height: 80px;
  width: 100%;
  background-size: cover;
  background-position: 0px center;
  margin: auto;
  max-width: 250px;
}

.left-side-menu.hide-menu .toggle-menu span.FaBars,
.left-side-menu.show-menu .toggle-menu span.FaTimesCircle {
  display: none;
}

.left-side-menu.hide-menu .toggle-menu span.FaTimesCircle,
.left-side-menu.show-menu .toggle-menu span.FaBars {
  display: block;
}

.lef-menu-container {
  display: flex;
  flex: 1;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
}

/*:not(.bullet-menu)*/
.lef-menu-container ul {
  margin: 10px 0px;
  padding: 0px;
  list-style: none;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  flex: 1; */
}

.lef-menu-container.bullet-menu {
  align-items: flex-start;
}

.menu-dot {
  visibility: hidden;
  font-size: 30px;
  height: 13px;
  display: inline-block;
  margin-right: 5px;
  line-height: 10px;
}

.lef-menu-container ul li a {
  padding: 12px 30px;
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  color: #8a959e;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-weight: bold;
}

.lef-menu-container ul li a .icon-container {
  flex: 1;
  align-items: center;
  display: flex;
}

.lef-menu-container.bullet-menu ul li a {
  /* padding: 15px 0px; */
  display: flex;
  align-items: center;
}

.lef-menu-container ul li.active a span,
.lef-menu-container ul li:hover a span {
  color: #00af85 !important;
}

.lef-menu-container ul li.active .menu-dot,
.lef-menu-container ul li:hover .menu-dot {
  visibility: visible;
}

.lef-menu-container ul li a svg {
  vertical-align: middle;
}

.lef-menu-container ul li a span.menu-label {
  margin-left: 5px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #8A959E;
}

.lef-menu-container ul li a .icon-container .icon-user + span.menu-label, .icon-bulkupload + span.menu-label{
  margin-bottom: 1px;
}

.left-help-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: inherit !important;
}

.lef-menu-container.left-back-arrow ul li a {
  padding: 15px 5px;
}

.logo {
  height: 100px;
  width: 250px;
  background-image: url(Images/gryd_logo.png);
  /* background-image: url(Images/gryd_logo_transparent.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 10px auto;
}

.left-side-menu.min-nav {
  width: 100px;
  /* background: #ffffff; */
}

.left-side-menu.min-nav .lef-menu-container ul li a span.menu-label {
  display: none;
}

.left-side-menu.min-nav .lef-menu-container ul li a {
  padding: 0px 25px;
}

.left-side-menu.min-nav .lef-menu-container ul li a button,
.left-side-menu.min-nav .lef-menu-container ul li.active a button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 36px;
  width: 42px;
  height: 42px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.left-side-menu.min-nav .lef-menu-container ul li.active a button {
  background: #DFF1ED;
  border-radius: 40px;
}

.left-side-menu.min-nav .lef-menu-container ul li #enforcements .MuiIconButton-label{
  margin: 1px 0px 0px 2px;
}

.left-side-menu.min-nav .lef-menu-container ul li .MuiIconButton-label{
  margin: 0px 1px 0px 0px;
}

.left-side-menu.min-nav .lef-menu-container ul li #Disbursements .MuiIconButton-label{
  margin: 0px 1px 4px 0px;
}

.left-side-menu.min-nav .lef-menu-container ul li #secured-parking .MuiIconButton-label,
.left-side-menu.min-nav .lef-menu-container ul li #lot-management .MuiIconButton-label{
  margin: 0px 0px 2px 2px;
}

.left-side-menu.min-nav .lef-menu-container ul li #Users .MuiIconButton-label,
.left-side-menu.min-nav .lef-menu-container ul li #bulk-upload .MuiIconButton-label,
.left-side-menu.min-nav .lef-menu-container ul li #Companies .MuiIconButton-label{
  margin: 2px 0px 0px 0px;
}

.left-side-menu.min-nav .lef-menu-container ul li #Companies .MuiIconButton-label{
  margin: 3px 0px 0px 0px;
}

.left-nav-icon {
  margin-left: 1px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-dashboard {
  background-image: url('./Images/left-nav-icons/dashboard_icon.svg');
  width: 18px;
  height: 18px;
}

.icon-properties {
  background-image: url('./Images/left-nav-icons/properties_icon.svg');
}

.icon-zone {
  background-image: url('./Images/left-nav-icons/zone_icon.svg');
}

.icon-spot {
  background-image: url('./Images/left-nav-icons/spot_icon.svg');
}

.icon-booking {
  background-image: url('./Images/left-nav-icons/booking_icon.svg');
}

.icon-bulkupload {
  background-image: url('./Images/left-nav-icons/bulkupload_icon.svg');
}

.icon-calendar {
  background-image: url('./Images/left-nav-icons/calendar_icon.svg');
}

.icon-cashbooking {
  background-image: url('./Images/left-nav-icons/cash-booking_icon.svg');
}

.icon-companies {
  background-image: url('./Images/left-nav-icons/companies_icon.svg');
  background-position-y: center;
}

.icon-disbursement {
  background-image: url('./Images/left-nav-icons/disbursement_icon.svg');
}

.icon-lotmanagement {
  background-image: url('./Images/left-nav-icons/lotmanagement_icon.svg');
}

.icon-parkingpassess {
  background-image: url('./Images/left-nav-icons/parking-passess_icon.svg');
  background-position-y: center;
}

.icon-report {
  background-image: url('./Images/left-nav-icons/report_icon.svg');
}

.icon-secureparking {
  background-image: url('./Images/left-nav-icons/secure_parking_icon.svg');
}

.icon-user {
  background-image: url('./Images/left-nav-icons/user_icon.svg');
}

.icon-visitorparking {
  background-image: url('./Images/left-nav-icons/visitor-parking_icon.svg');
}

.icon-enforcement {
  background-image: url('./Images/left-nav-icons/enforcement_icon.svg');
}

.icon-help {
  background-image: url('./Images/left-nav-icons/help_icon.svg');
}

.left-side-menu.min-nav .lef-menu-container ul li.active a button.active .left-nav-icon,
.left-side-menu.min-nav .lef-menu-container ul li a button:hover .left-nav-icon,
.left-side-menu .lef-menu-container ul li.active.submenu-bg-color a .icon-container .left-nav-icon,
.left-side-menu .lef-menu-container ul li:hover a .icon-container .left-nav-icon{
  filter: invert(46%) sepia(57%) saturate(1408%) hue-rotate(132deg) brightness(90%) contrast(101%)
}

.left-side-menu.min-nav .lef-menu-container ul li.active a button,
.left-side-menu.min-nav .lef-menu-container ul li a button:hover {
  color: #00af85;
}

.left-side-menu.min-nav .MuiIconButton-root:hover {
  background: rgba(223, 241, 237, 0);
}

.lef-menu-container .MuiIconButton-root {
  color: rgba(138, 149, 158, 1);
}

.left-side-menu.min-nav .logo {
  /* background-image: url(Images/gryd_logo_transparent.png);
  background-repeat: no-repeat; */
  background-position: -5px -7px;
  background-size: auto 80px;
  /*margin: 10px auto;*/
  width: 54px;
}

.profile-icon {
  font-size: 15px !important;
  background: #636fbe !important;
}

.icon-container {
  white-space: nowrap;
}

.profile-popup .MuiMenu-paper {
  padding: 5px !important;
  box-shadow: 0 8px 6px -6px black;
}

@media only screen and (max-height: 630px) {
  .lef-menu-container {
    align-items: flex-start;
  }
}

.list-header,
.gryd-h1,
.wizard-title {
  font-weight: bold;
}

.list-header,
.gryd-h1 {
  font-size: 25px;
  margin: 10px 0px;
}

.h2,
.wizard-title {
  font-size: 22px !important;
}

.swal2-container.swal2-center {
  z-index: 1400;
}

.swal2-actions .swal2-confirm,
.swal2-actions .swal2-deny {
  background-color: #00af85 !important;
  box-shadow: none !important;
}

.swal2-actions-custom {
  flex-direction: column;
  align-items: inherit !important;
}

.swal2-actions-custom button {
  flex: 1;
  font-size: 15px !important;
}

.swal2-content {
  font-size: 15px !important;
}

.swal2-title {
  font-size: 18px !important;
  font-weight: bold !important;
}

.swal2-icon {
  transform: scale(0.5, 0.5) !important;
  margin: 0px !important;
  animation: none !important;
}


.filter-view,
.reservation-edit-view .cash-booking-edit-view {
  width: 500px;
}

.rdrMonth {
  width: 100% !important;
}

.notification-body {
  padding: 20px;
}

.data-grid-container>div.MuiGrid-container,
.table-list-header-fixed {
  position: absolute;
  top: 13px;
  width: calc(100% - 85px);
  /* width: calc(100% - 135px); */
  /* width: calc(100% - 200px); */
}

.right-pannel-body {
  max-width: 500px;
}

.data-grid-container>div.MuiGrid-container {
  padding-right: 16px;
}

.data-grid-container.property-dashboard-container>div.MuiGrid-container {
  top: 0px;
}

.property-dashboard-container {
  overflow: auto;
}

.fullscreen-modal-appbar-title {
  line-height: 2.5em !important;
}

.fullscreen-modal-body {
  margin-top: 80px;
  height: calc(100% - 80px);
}

/* .MuiDrawer-docked {
  z-index: 10001;
} */

.dashboard-container {
  overflow: auto;
}

.profile-container.m-3 {
  background: #ffffff;
  padding: 0px 20px !important;
  margin: 0px !important;
}

/*Search UI*/

.table-search-blur .table-search-input-container {
  background: none;
  box-shadow: none;
}

.table-search-blur .table-search-input {
  width: 0px;
}

.table-search-input .MuiInputBase-input {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block !important;
}

.rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrDayHovered,
.rdrSelected,
.rdrDayStartPreview,
.rdrDayEndPreview {
  color: #00b188 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #00b188 !important;
}

.toastr {
  z-index: 99999 !important;
}

.MuiPopover-paper .MuiPaper-root {
  width: auto !important;
}

.MuiPopover-paper button {
  white-space: nowrap;
  display: block !important;
}

.hide-beacon #beacon-container {
  display: none;
}

.vertical-fill {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.no-wrap-text {
  white-space: nowrap;
}

/*Loading Widger Start here*/
#widgetSpinnerScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*background: url(../images/spinner.gif) no-repeat center, rgba(0,0,0,0.5);*/
  background: rgba(0, 0, 0, 0.5);
  z-index: 20001;
}

.spinner {
  /* margin: 100px auto 0; */
  width: 70px;
  text-align: center;
}

.spinner>div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }

  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.mt-4-px {
  margin-top: 4px !important;
}

.mb-px-0 {
  margin-bottom: 0px !important;
}

.mt-px-5 {
  margin-top: 5px !important;
}

.ma-pa-bo {
  margin: 25px 0px;
  padding: 14px 20px;
  background: #fff;
  border-radius: 10px;
}

.ma-pa-bo h5 {
  font-size: 14px;
  font-weight: 700;
}

.mr-px-30 {
  margin-right: 30px !important;
}

.mt-px-8 {
  margin-top: 8px !important;
}

.mt-px-5 {
  margin-top: 5px;
}

.mt-px-10 {
  margin-top: 10px !important;
}

.mt-px-15 {
  margin-top: 15px !important;
}

.mt-14-px {
  margin: 14px 0px 0px 0px !important
}

.mr-px-8 {
  margin-right: 8px !important;
}

.mt-px-40 {
  margin-top: 40px !important;
}

.pt-px-10 {
  padding: 10px;
}

.ml-px-minu25 {
  margin-left: -25px !important;
}

.ml-px-8 {
  margin-left: 8px !important;
}

.ml-px-30 {
  margin-left: 30px !important;
}

.ml-px-minus30 {
  margin-left: -30px !important;
}
/*Loading Widger End here*/

.mt-6{
  margin-top: 6rem!important
}

.w-400-px{
  width: 400px;
}

.pl-0-px {
  padding-left: 0px!important;
}
/*Loading Widger End here*/
.secondary-server-marker {
  position: fixed;
  bottom: 0;
  left: 100px;
}

.sp-le-c-10-R {
  margin: 25px 0px;
  padding: 14px 12px 14px 30px;
  background: #fff;
  border-radius: 10px;
}

.sp-le-c-10-R h5 {
  font-size: 14px;
  font-weight: 700;
}

.sp-le-c-right {
  text-align: right;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 0.5rem !important;
}

.ignoreUnit .ignoreUnitCloseIcon{
  height: 15px !important;
}

.MuiButton-root.ignoreUnit:hover{
  background-color: #fff;
}

.disble-control{
  pointer-events: none;
  opacity: 0.7;
}

.c-rp-c-common {
  margin: 0px 35px;
}

.mb-px-0 {
  margin-bottom: 0px !important;
}

.right-align {
  text-align: 'right';
}

.ml-10-px {
  margin-left: 10px !important;
}

.common-header {
  /* background-color: #fff; */
  top: 0 !important;
  /* padding-top: 12px; */
  /* padding-bottom: 15px; */
}

.common-parent-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding-right: 1rem;
  padding-left: 1rem;
}

.notification-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: #f8f8f8;
  flex-direction: column;
  z-index: 100;
}

.common-notification-body {
  flex: 1;
  overflow: auto;
  position: relative;
}

.common-notification-sub-body {
  position: absolute;
  padding-bottom: 40px;
}

.sp-le-c {
  margin: 25px 0px;
  padding: 14px 30px;
  background: #fff;
  border-radius: 10px;
}

.bl-s-button {
  padding: 3.7px 16px !important;
  font-size: 15px !important;
}

.common-lf-header-container {
  flex: 1;
  background: #FFFFFF;
  padding-top: 12px;
  padding-bottom: 15px;

}

.common-more-details {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    text-decoration: underline !important;
    color: #00AF85 !important;
    background-color: #fff !important;
    cursor: pointer;
}

.check-icon-size {
  font-size: 2.5rem !important;
  color: #00AF85;
}

.error-icon-size {
  font-size: 2.5rem !important;
  color: #CF1818;
}

.notify-header {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}

.notify-active {
  background: #DADDDF;
}

.notification-border:not(:last-child) {
  border-bottom: 1px solid #8A959E;
}

.secure-error-mg {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #CF1818;
}

.btn-notification {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.close-btn {
  cursor: pointer;
}

.n-setup-bg-color,.n-setup-bg-color:hover{
  background-color:#F8F8F8 !important;
}

.n-toast-close{
  align-items: flex-start;
}

.percentage-textfield {
  margin: 10px 0px !important;
}

.percentage-textfield input {
  text-align: right;
}

.disbursement-export {
  margin-right: 10px !important;
}

a.suppress-link, a.suppress-link:hover {
  color: inherit;
  text-decoration: none;
}