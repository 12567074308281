.visitor-passcode {
    min-width: 82px;
    display: inline-block;
    /* font-size: 20px; */
    line-height: 12px;
    /* border: 1px solid; */
    height: 14px;
    letter-spacing: 8px;
}

.pointer {
    cursor: pointer;
}

.passcode-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.data-grid-container>div.MuiGrid-container.visitor-parking-header {
    width: calc(100% - 56px);
}

