.data-grid-th {
    color: #8a959e !important;
    padding: 8px 2px !important;
    background: #ffffff;
    font-weight: bold !important;
    text-transform: uppercase
}

.th-list-head {
    padding: 0px 4px !important;
}

.data-flex-box {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex: 1;
}

.data-grid-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.data-grid-table-container-list {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.data-grid-paper-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.data-grid-table-container {
    /* display: flex; */
    flex: 1;
    min-height: 400px;
    flex-basis: 0;
    /* max-height: 430px; */
}

.filter-view,
.edit-view,
.detailed-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 500px;
}

.filter-view.px-3 {
    padding-right: 5px !important;
}

.filter-view .MuiIconButton-edgeEnd {
    margin-right: 0px !important;
}

.filter-options-list,
.panel-view-scrollable-area {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    flex-basis: 0;
}

.data-grid-right-panel {
    width: 100%;
}

.data-grid-right-panel .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    overflow: hidden !important;
    /* position: relative; */
    width: 500px;
}

.MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5) !important;
}

.filter-panel.Mui-expanded {
    padding-bottom: 20px;
}

.list-edit-view {
    /* padding: 0px 10px; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 0;
}

.grid-list-addedit-sub {
    flex: 1;
    overflow: auto;
    padding: 10px;
}

.grid-addedit-actions {
    padding: 10px;
}

.data-grid-table-cell {
    /* max-width: 100px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
}

.clickable-rows .MuiTableRow-hover {
    cursor: pointer;
}

.wrapcell {
    /* width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; */
    word-break: break-all;
}

.multiPopoverButton {
    text-align: left;
}

.sort-visibility .MuiTableSortLabel-icon{
    opacity: 0 !important;
}

.group-header-ht {
    height: 63px;
}

.group-header-span {
    color: #8A959E;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 20px;
}