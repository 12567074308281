html,
body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  scrollbar-face-color: #464646;
  scrollbar-arrow-color: #FFFFFF;
  scrollbar-track-color: #ffffff;
  scrollbar-shadow-color: rgb(0, 0, 0);
  scrollbar-highlight-color: rgb(0, 0, 0);
  scrollbar-3dlight-color: #808080;
  scrollbar-darkshadow-Color: #202020;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  border-bottom: 2px solid #00b089;
  padding: 5px 10px;
}

.header-container {
  padding: 0px !important;
}

header img {
  height: 60px;
}

#root {
  display: flex;
  flex: 1;
  height: 100vh;
}

.App {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.body-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}


/* Added for registration screen */

.App.register-page,
.App.login-page {
  display: flex;
  flex: 1;
  /* height: 100vh; */
}


/*List Style*/

/* .list-header {
  font-size: 30px;
} */

.add-list-button {
  margin-top: 16px !important;
  /* background: #000000 !important; */
}

.list-button {
  /* background: #000000 !important; */
  border-radius: 100px !important;
  color: #ffffff !important;
  min-width: 100px !important;
}

.edit-button-container {
  align-items: center;
  margin-bottom: 10px;
}

.list-button .MuiButton-label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.list-button.list-button-cancel {
  background: #f3f4f5 !important;
  color: #8a959e !important;
}

.button-container {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
}

.add-list-button.MuiButton-root {
  border-radius: 100px !important;
  padding: 6px 30px !important;
  line-height: 1.5 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: bold;
}

.footer-pagenation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-right-menu {
  width: 100%;
  max-width: 500px;
  /* background: #000; */
  /* padding: 10px; */
  padding: 10px 0px 10px 10px;
  border-left: 2px solid #cccccc;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background: #ffffff;
  z-index: 1000;
  /* margin-right: -5px; */
  position: fixed;
  right: 0px;
  top: 0px;
  /* z-index: 10001; */
  height: 100%;
}

.table-right-menu .list-header {
  margin-top: 0px;
}

.closed-menu {
  /* margin-right: -410px; */
  border: 0px;
  visibility: hidden;
  width: 0px !important;
  padding: 0px;
  display: none;
}

.close-menu-icon {
  text-align: right;
  padding-right: 10px;
}

.list-sub-header {
  margin: 10px 0px 0px 0px;
  font-size: 15px;
  color: #8f99a2;
  text-transform: uppercase;
  font-weight: bold;
}

.right-menu-body {
  flex: 1;
  overflow: auto;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

.table-lis-footer .MuiTablePagination-actions {
  display: none;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: rgba(0, 0, 0, 1) !important;
  color: #fff !important;
}

.table-container-list .th-list-thead th:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.table-container-list .th-list-thead th:after {
  bottom: -1px;
  /* border-bottom: 3px solid #000000; */
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: inherit !important;
}

.table-list-cell {
  /* font-family: "Helvetica" !important; */
  padding: 13px 5px !important;
}

.side-popup-container {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  flex: 1;
  overflow: hidden;
}

.property-state,
.spot-state {
  position: relative;
  padding: 0px 10px;
  background: #f1f2f3 !important;
  border-radius: 100px;
}

.property-state .MuiSelect-select,
.spot-state .MuiSelect-select {
  background: #f1f2f3 !important;
  color: #909ba3 !important;
  font-weight: bold;
  font-size: 15px;
}

.property-label {
  display: block;
  user-select: none;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  content: none !important;
  border: 0px !important;
  display: none;
}

.property-location {
  color: #8a959e;
  line-height: 20px;
  font-size: 18px;
}

.property-location svg {
  vertical-align: middle;
}

hr.property-line {
  border: 2px solid #000;
  width: 100%;
}

.property-location-img {
  margin: 20px 0px;
}

.icon-selected {
  color: #000000;
  background: rgba(0, 0, 0, 0.1) !important;
}

.bold-caps {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 5px;
}

.table-label {
  color: #8a959e;
}

.revenue-lable {
  font-size: 20px;
}

.revenue-amount {
  font-size: 25px;
}

.revenue-amount-container {
  padding-bottom: 30px;
}

.check-box-table.MuiCheckbox-colorSecondary.Mui-checked,
.check-box-table.MuiCheckbox-indeterminate {
  color: #42bffd !important;
}

.disbursement-setting .Mui-checked {
  color: #00bf6f !important;
}

.MuiTypography-body1 {
  user-select: none;
}

.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: rgba(66, 191, 253, 0.08) !important;
}

.add-lit-button {
  background: #00af85 !important;
  color: #ffffff !important;
}

/*Status*/

.list-status {
  width: 10px;
  height: 10px;
  border: 2px solid #000000;
  border-radius: 100%;
}

.list-status.active {
  border-color: #00bf6f;
}

.inactive {
  border-color: #df5a37;
}

.pending {
  border-color: #FFFF00;
}

.list-status.dropdown-status {
  display: inline-block;
  margin-right: 5px;
}

.clear-filter {
  color: #8a959e;
  padding-right: 20px;
  font-weight: bold;
}

.clear-filter:hover {
  cursor: pointer;
  text-decoration: underline;
}

.chip-lable {
  margin: 0px 5px 10px 0px;
}

.table-search-container {
  display: inline-block;
}

.swal2-container.swal2-shown {
  z-index: 25000;
}

.map-container {
  position: relative;
  width: 100%;
  height: 200px;
  min-height: 200px;
  display: block;
  /* overflow: hidden; */
  margin: 10px 0px 20px 0px;
  border: 1px solid #000000;
}

.map-container>div {
  height: 100%;
}

.carousel.hide-controls .carousel-control-next,
.carousel.hide-controls .carousel-control-prev {
  display: none;
}

.carousel-control-next,
.carousel-control-prev {
  height: 40px;
  margin: auto;
  border-radius: 100%;
  width: 40px !important;
  background: #a08484;
  /* border: 2px solid #9df32c; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); */
}

.carousel-control-prev {
  left: 20px !important;
}

.carousel-control-next {
  right: 20px !important;
}

.carousel-img-boots {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-position: center !important;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.carousel-wrapper-custom {
  width: 100%;
  /* margin: 10px 0px; */
}

.carousel-item-custom {
  height: 200px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}

.carousel-wrapper-custom button {
  background: #948e8e !important;
}

.carousel-indicators li {
  border: 1px solid #333 !important;
}

.ifmzxn {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.dummy-dashboard {
  width: 100%;
  overflow: auto;
}

.add-button {
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.add-button:hover {
  text-decoration: underline;
}

.forgot-text {
  color: #8e99a2;
  font-size: 18px;
  line-height: 30px;
  margin: 20px 0px;
}

.table-container-list .MuiTabs-root {
  background: #ffffff;
}

.MuiTab-wrapper {
  text-transform: initial;
}

.selects-none {
  user-select: none;
}

.swal2-styled {
  padding: 6px 16px !important;
}

.swal2-cancel {
  background: #f3f4f5 !important;
  color: #949292 !important;
}

.swal2-content {
  color: #000000 !important;
}

.popup-add-module .MuiDialog-paper {
  width: 100%;
}

.popup-add-module .add-user-appbar {
  background: none !important;
}

.popup-add-module .MuiToolbar-gutters {
  padding: 0px 10px !important;
}

.popup-add-module .react-tel-input .country-list {
  position: fixed !important;
}

.popup-add-module .form-sub-container {
  max-width: inherit !important;
}

.submenu-bg-color{
  background: #DFF1ED;
}