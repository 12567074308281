.proflie-container {
    flex: 1;
    margin-left: 5%;
    /* border-radius: 5px;
    border-color: "#ffffff";
    border-style: solid; */
}

.proflie-name-section {
    font-size: 15px;
    margin-bottom: 20px;
}

.info-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 500px;
}

.profile-footer {
    margin-left: 5%;
    max-width: 300px;
}

.change-text {
    text-align: right;
    color: #36c0a0;
}

.preference-div-container {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.profile-label {
    font-size: 16px;
    font-weight: bold;
}

.change-password-container {
    margin: 5px 0px;
}

.preference-text {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.preference-switch {
    /* padding: 10px !important; */
    text-align: center;
    margin-left: 100px !important;
}

.profile-change-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: "100%";
}

.proflie-dialog {
    flex: 1;
    display: flex;
}

.profile-dialog-container {
    flex: 1;
    max-width: 500px;
    margin: 0px auto;
    padding: 20px;
    text-align: center;
    align-items: center;
}


.payment-button {
    text-align: right;
    margin-left: 100px !important;
    font-size: 25;
    color: '#fff';
    background-color: '#4CAF50';
    border-radius: 5;
    width: 90;
    height: 50;
}

.payment-div-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}