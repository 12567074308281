.login-page {
    flex-direction: row !important;
}

.login-banner {
    background: url('../Images/login-banner.jpg') no-repeat;
    background-size: cover;
    background-position: right;
    flex: 1;
}

.login-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 20px;
    min-width: 400px;
    margin: auto;
    max-width: 400px;
    border-radius: 5px;
    overflow: auto;
}

.signInMessage {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.input,
.input label,
.input input,
.input .spin,
.button,
.button button {
    width: 100%;
    float: left;
}

.input {
    margin-top: 10px;
    height: 50px;
    display: flex;
}

.username-box {
    /* margin-top: 0px; */
    margin: 10px 0px;
}

.username-box input[type="number"] {
    -moz-appearance: textfield;
}

.username-box input[type="number"]::-webkit-outer-spin-button,
.username-box input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input,
.input input {
    position: relative;
}

.input input {
    height: 40px;
    border: none;
    background: transparent;
    outline: 0;
    font-size: 18px;
    border: 2px solid #ccc;
    padding: 5px 10px;
    flex: 1;
}

.input input:focus {
    border: 2px solid #00b089;
}

.input input.error {
    border: 2px solid #ff0000;
    box-shadow: 0 5px 5px -5px #ff0000;
}

.login-container .error-msg {
    /* position: absolute; */
    bottom: 0;
    margin: 10px 0px;
    color: #ff0000;
    font-size: 18px;
}

.body-container.alpha .motion-button {
    margin: 30px auto 40px auto;
}

.login-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.motion-button-login {
    background: #ffffff;
    color: #00b089;
    border: 2px solid #00b089;
    /* text-transform: uppercase; */
    font-size: 20px;
    margin: 20px auto;
    border-radius: 5px;
    display: block;
    padding: 10px;
    cursor: pointer;
    min-width: 200px;
    width: 100%;
    font-family: "Helvetica Condensed";
    outline: 0;
    text-transform: uppercase;
}

.motion-button-login:hover {
    background: #00b089;
    color: #FFFFFF;
}

button:disabled {
    color: graytext !important;
    background-color: buttonface !important;
    border-color: buttonface !important;
}

.hide {
    display: none;
}

.EditButton {
    cursor: pointer;
}

.highlight {
    color: #008CBA;
}

.resend {
    color: #00b089;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.eyePos {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

@media only screen and (max-height: 550px) {
    .login-container {
        display: block;
        overflow: auto;
    }
}

@media only screen and (max-width: 1023px) {
    .App.login-page {
        flex-direction: column !important;
    }

    .login-container {
        margin: auto;
    }
}

@media only screen and (max-height: 550px),
(max-width: 1023px) {
    .login-banner {
        display: none;
    }

    .App.login-page {
        display: flex;
        overflow: auto;
    }

    .login-container {
        overflow: visible;
        margin: auto;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
}

@media only screen and (max-width: 500px) {
    .login-banner {
        display: none !important;
    }

    .login-container {
        margin: 0px !important;
        min-width: inherit !important;
        max-width: inherit !important;
    }
}

.root {
    /* height: '100vh'; */
    flex: 1;
}

.sign-up-not-member {
    align-items: center;
    justify-content: left;
    display: flex;
}

.not-a-member {
    padding: 20px 32px 0px 0px;
    text-align: left;
}

.login-page a {
    color: #f2994a !important;
}

.resendOtp {
    margin: 10px 0px !important;
    display: inline-block;
}

.login-form-container {
    display: flex;
    flex-direction: column;
}

.sign-in-button-icon {
    margin-left: 20px;
}

.image {
    background-image: url('../Images/ladning-banner.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 0;
    background-color: #000;
}

.login-paper {
    margin: 10px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.avatar {
    margin: 1;
    background-color: #ffffff;
}

.login-paper .form {
    width: 100%;
    margin-top: 1px;
    max-width: 440px;
}

.submit {
    margin: 3 0 2;
}

.sigin-link {
    margin: 10px 0px !important;
    display: block;
}

.react-tel-input .form-control {
    width: inherit !important;
}

.react-tel-input .form-control+div:before {
    left: 10px !important;
    content: 'Phone' !important;
}

.react-tel-input.Mui-disabled .form-control+div:before {
    color: rgba(0, 0, 0, 0.38);
}

.login-title {
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 36px;
}

/* .logo {
    max-width: 200px;
} */

.login-button {
    margin-top: 16px !important;
    background: #00b188 !important;
    white-space: nowrap;
}


.login-button .MuiButton-label {
    padding: 5px;
}

.react-tel-input input.form-control:disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: default;
    border: 1px solid #CACACA !important;
}

.otp-messsage {
    font-size: 18px;
    text-align: center;
}

.otp-messsage.otp-small {
    font-size: 15px;
}

.otp-messsage .bold {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.MuiInputLabel-outlined {
    padding-right: 10px !important;
    background: #fff;
}

.errormsg {
    color: #f44336;
    margin-left: 14px;
    margin-right: 14px;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-weight: bold;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.MuiFormHelperText-root{
    font-family: "Manrope",sans-serif !important;
}

.MuiButton-root {
    text-transform: none !important;
    font-size: 16px !important;
}

.login-logo {
    max-width: 240px;
    margin: 5px auto;
    display: block;
}

.captcha-container {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-show {
    color: #00AF85;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    user-select: none;
}

.email-container,
.password-container {
    position: relative;
}

.email-logo,
.password-logo {
    position: absolute;
    top: 32px;
    margin: auto;
    left: 15px;
    color: #8A959E;
    z-index: 1;
}

.password-logo {
    top: 15px !important;
    width: 24px;
}

.email-container .MuiInputLabel-outlined,
.password-container .MuiInputLabel-outlined {
    transform: translate(50px, 20px) scale(1);
}

.email-container .MuiOutlinedInput-input,
.password-container .MuiOutlinedInput-input {
    padding-left: 50px;
}

.locked-message {
    border: 2px solid #FA4616;
    border-radius: 10px;
    padding: 32px;
    margin: 25px 0px;
    background: rgba(250, 70, 22, 0.1);
    font-weight: 600;
    color: #111012;
}

.locked-message a {
    color: #111012 !important;
    font-weight: bold;
    text-decoration: underline;
}

@media only screen and (min-width: 724px) {
    .locked-message {
        margin: 25px -20%;
        width: 140%;
    }
}