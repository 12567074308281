input.hidden {
    display: none;
}
label.full-size {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.file-upload-modal .file-upload-modal-box {
    width: 600px;
    height: 400px;
    padding: 16px;
    background: #ffffff;
    margin: auto;
    margin-top: 100px;
}

.file-upload-modal .file-dropbox {
    min-height: 100px;
}

.file-upload-modal .file-dropbox > .file-drop-target {
    position: relative;
    height: 100%;
    border: 1px dashed #cccccc;
}

.file-upload-modal .file-dropbox > .file-drop-target.file-drop-dragging-over-frame {
    border-color: #000000;
}

.file-upload-modal .file-dropbox > .file-drop-target.file-drop-dragging-over-target {
    border-color: #00af85;
}

.file-upload-modal .dropbox-content {
    position: absolute;
    height: 70px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.file-upload-modal .dropbox-content button {
    width: 140px;
}

.file-upload-modal .file-list {
    max-height: 190px;
    overflow-y: scroll;
}

.file-upload-modal .file-preview {
    min-height: 150px;
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


.file-upload-modal .file-details {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #00000055;
}

.file-upload-modal .file-name {
    padding: 0px 8px;
    line-height: 32px;
    height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #ffffff;
}


.file-upload-modal .btn-select-file {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 36px;
    background-color: #00000055 !important;
}



/* OLD ONES */
/* Common Upload Control */
.image-upload-control .file-drop > .file-drop-target.file-drop-dragging-over-frame,
.file-upload-control .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    outline: 2px dashed #999999;
}
  
.image-upload-control .file-drop > .file-drop-target.file-drop-dragging-over-target,
.file-upload-control .file-drop > .file-drop-target.file-drop-dragging-over-target {
    outline: 2px dashed #000000;
}

.btn-file-upload {
    position: relative;
    width: 100%;
    /* font-size: 0.8em !important;
    text-transform: uppercase !important; */
}

.btn-file-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.image-upload-control .image-name,
.file-upload-control .image-name, .file-upload-control .no-image {
    height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


/* Image Upload Control */
.image-upload-control {
    width: 200px;
}

.image-upload-control .image-preview {
    position: relative;
    border: 1px solid #eeeeee;
}

.image-upload-control .image-details {
    position: absolute;
    bottom: 0;
    background: #00000099;
}

.image-upload-control .image-name {
    padding: 0px 8px;
    line-height: 32px;
    color: #ffffff;
}

.image-upload-control .image-preview>img {
    width: 100%;
}

/* File Upload Control */
.file-upload-control {
    width: 200px;
}

.file-upload-control .image-name, .file-upload-control .no-image {
    line-height: 32px;
}



/* Text Tags container */
.text-tags-container {
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.text-tags-container .MuiChip-root {
    margin: 4px;
}

.text-tags-container .chip-tag.skipped {
    background-color: #c3c3c3;
}

.tag-list {
    max-height: 200px;
    overflow-y: auto;
}

.btn-remove-all-tag {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
    font-size: 0.7em;
}


.label-control-label {
    font-weight: bold !important;
    text-transform: uppercase;
}