.list-status-text {
    line-height: 2em;
    font-weight: bold;
}

.list-status-text.paid {
    color: #00af85 !important;
}

.list-status-text.subscription-cancelled {
    color: #cebb14 !important;
}

.list-status-text.reserved {
    color: #999 !important;
}

.reservations-page .MuiInputLabel-outlined {
    background: transparent;
}

.reservation-calendar-view {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: row;
}

.cancel:hover {
    cursor: pointer;
}













/* Global stuff */
body,
header {
    background-color: #f8f8f8 !important;
}


.ccMonthSelector {
    border-bottom: 3px solid #eee;
    text-align: center;
}

.month-name {
    font-weight: bold;
    line-height: 44px;
    width: 80px;
}

.btn-prev-month,
.btn-next-month {
    width: 80px;
    cursor: pointer;
    display: inline-block;
    line-height: 40px;
    vertical-align: top;
}

/* Calendar component overrides */
.month-view {
    width: 100%;
}

.month-view .rbc-date-cell {
    position: relative;
}

.month-view .rbc-row-content,
.month-view .rbc-row-content>.rbc-row {
    position: relative;
    height: 100%;
}

.month-view .day-number {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 24px;
    width: 100%;
    text-align: center;
}

.month-view .rbc-event {
    margin-top: -30px;
    background: none !important;
    color: #000;
    text-align: center;
    pointer-events: none;
    font-size: 0.8em;
}

/* Month view coluring */
.month-view .rbc-now,
.month-view .rbc-today,
.month-view .rbc-off-range-bg {
    background-color: transparent !important;
}

.month-view .d-selected {
    border: 1px solid #333 !important;
    background-color: #f9fafa !important;
}

/* Day View */
.day-view {
    padding-left: 20px;
    width: 100%;
}

/* .day-view, .reservation-list-view, .schedule-view {
    background-color: #fff;
} */

.day-view .rbc-calendar {
    position: relative;
    overflow-y: scroll;
    /* max-height: 400px; */
    border-top: 1px solid #DDD;
    line-height: 2em !important;
}

.day-view .rbc-time-view {
    position: absolute;
}

.day-view .rbc-time-header {
    display: none;
}

.day-view .rbc-today,
.day-view .rbc-time-column {
    background-color: white !important;
}

.rbc-timeslot-group {
    min-height: 16px !important;
    border: none;
}

.rbc-time-content>*+*>* {
    border: none;
}

.rbc-timeslot-group .time-time-slot {
    border-bottom: 1px solid #DDD;
}

.rbc-day-slot .rbc-timeslot-group .time-time-slot {
    border-bottom-color: #DDD;
}

.day-view .rbc-day-slot .rbc-timeslot-group .time-time-slot {
    border-left: 1px solid #DDD;
}

/* .day-view .rbc-day-slot .rbc-timeslot-group:first-child .time-time-slot {
    border-top: 1px solid #DDD;
} */


.day-view .time-time-slot {
    padding: 0 24px;
    text-align: right;
}

.day-view .rbc-time-gutter .reservation-side {
    display: none;
}

.reservation-side {
    white-space: nowrap;
}

.day-view .rbc-time-column:not(.rbc-time-gutter) {
    text-align: right;
}

.day-view .rbc-time-column:not(.rbc-time-gutter) .time-side {
    display: none;
}

.day-view .rbc-time-view,
.day-view .rbc-time-content {
    border: none !important;
}

.day-view .rbc-day-slot {
    position: relative !important;
    /* padding-top: 15px; */
}

.day-view .rbc-time-content {
    position: relative;
}

.day-view .rbc-current-time-indicator {
    background-color: #f008 !important;
    height: 2px;
    left: 16px;
}

.day-view .rbc-current-time-indicator:before {
    position: absolute;
    left: -6px;
    top: -2px;
    width: 6px;
    height: 6px;
    background-color: #f008;
    border-radius: 100%;
    content: "";
}



hr {
    margin: 0 !important;
    border-top: 2px solid #eee !important;
}

.right-header h5 {
    margin: 0;
    line-height: 40px;
}

.btn-back {
    cursor: pointer;
}

.text-grey {
    color: #999;
}

/* .day-view .expired, */
.legend-color.past-reservation,
.day-view .booked.expired {
    background-color: #6ac7e3;
    color: #000000;
}

/* .day-view .future, */
.legend-color.upcoming-reservation,
.day-view .booked.future {
    background-color: #39b999;
    color: #000000;
}

/* .day-view .current, */
.legend-color.current-reservation,
.day-view .booked.current {
    background-color: #ffaa59;
    color: #000000;
}

.day-view .blocked {
    background-color: #323232;
    color: #ffffff;
}

.day-view .rbc-day-slot .temp-selected {
    background-color: #7f7f7f;
    border-color: #6e6e6e;
}

.day-view .free,
.day-view .blocked {
    pointer-events: none;
}

.day-view .booked,
.reservation-list-view .schedule-entry {
    cursor: pointer;
}

.pull-right {
    float: right;
}


.icon-block {
    position: absolute;
    zoom: 1.3;
}

.legend {
    display: inline-block;
    margin-right: 24px;
}

.last-legend {
    margin-right: 0 !important;
}

.legend .legend-color {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.block-reservation-calendar {
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    z-index: 101;
    text-align: center;
    padding-top: 200px;
    font-size: 1.5em;
}

.day-view .rbc-event {
    width: 100% !important;
    left: 0 !important;
}

.day-view .rbc-addons-dnd-is-dragging .rbc-day-slot .rbc-event {
    border: 2px dashed #000 !important;
}

.day-view .rbc-event .rbc-addons-dnd-resize-ns-anchor {
    display: none;
}

/* .day-view .rbc-events-container {
    pointer-events: none;
} */

/*.time-grid-admin .rbc-events-container {
    display: none;
}*/

.day-view .rbc-events-container .rbc-event-label {
    display: none;
}

.day-view .rbc-day-slot .rbc-event {
    border: none !important;
    background-color: #3333;
    cursor: default;
    pointer-events: none;
}

.day-view .rbc-events-container {
    margin-right: 0 !important;
}

.day-view .block-entry {
    text-align: right;
    font-size: 0.7em;
    line-height: 2em;
}

.day-view .block-entry:not(.non-editable) {
    cursor: pointer;
    pointer-events: all;
    text-decoration: underline;
}

.booking-details-popup {
    padding: 10px;
}

.booking-details-popup h6 {
    font-size: 12px;
}

.booking-details-popup h3 {
    font-size: 16px;
}

.booking-details-container {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.edit-booking,
.mr-button {
    margin-right: 15px !important;
}

.edit-booking-modal .edit-booking-modal-box {
    min-width: 600px;
    max-width: 1024px;
    height: 500px;
    padding: 10px;
    background: #ffffff;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.edit-booking-modal-box {
    padding: 10px;
    background: #ffffff;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.eb-search-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.eb-search-flex .MuiOutlinedInput-adornedEnd {
    padding-right: 5px !important;
}

.eb-search-fields {
    flex: 1;
}

.edit-cls-btn {
    padding: 10px !important;
}

.eb-search-button {
    padding: 0px 10px;
}

.eb-search-button button {
    margin-top: 10px;
}

.eb-search-result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.eb-modal-body {
    display: flex;
    flex-direction: column;
}

.eb-modal-body,
.edit-booking-modal-body {
    flex: 1;
}

.eb-modal-body .MuiGrid-container {
    padding-top: 10px;
}

.edit-booking-modal .MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
}

.edit-booking-modal .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
}

.edit-booking-modal-header {
    /* margin-bottom: 30px; */
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.edit-booking-appbar-title {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 10px;
    text-align: left;
}

.boonav {
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #000000;
}

.boonav-circle {
    width: 20px;
    height: 20px;
    border: 4px solid #00af85;
    border-radius: 100%;
    background: #ffffff;
    position: relative;
    cursor: pointer;
}

.boonav-circle:hover,
.boonav-circle.active {
    background: #00af85;
}

.boonav-container {
    position: relative;
    display: flex;
    width: 150px;
}

.boonav-container:last-child {
    width: auto;
}

.boonav-dash {
    width: 150px;
    border: 2px solid #00af85;
    background: #00af85;
    height: 0px;
    position: absolute;
    left: 66px;
    top: 9px;
}

.boonav-circle-container {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking-map-container {
    position: relative;
}

.spot-selection {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.spot-list {
    display: flex;
    flex: 1;
}

.spot-selection-list {
    min-width: 250px;
    overflow: hidden;
    max-width: 350px;
}

.total-payment-Diff {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.spot-payment-diff {
    min-width: 400px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
}

.spot-payment-diff .total-payment-table {
    width: 100%;
    flex: 1;
}

.total-payment-table table {
    width: 100%;
}

.spot-payment-diff td {
    line-height: 40px;
}

.spot-payment-diff td:last-child {
    text-align: right;
}

.spot-payment-diff td:last-child span {
    padding-right: 20px;
}

.spot-payment-diff hr {
    border-color: #000 !important;
}

.spot-selection-map {
    flex: 1;
}

.selection-list-container {
    display: flex;
    flex-direction: column;
    height: 298px;
    overflow: auto;
    margin-right: 10px;
}

.spot-name-price {
    display: flex;
}

.spot-name {
    flex: 1;
    padding-right: 5px;
}

.spot-name .spot-prop-name {
    white-space: nowrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.spot-item {
    background: #ccc;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
}

.spot-item:hover,
.spot-item.active {
    background: #00af85;
    color: #ffffff;
    transition: all 0.8s ease-in-out;
}

.spot-item.active {
    cursor: default;
}

.spot-item p {
    font-size: 12px;
    margin-bottom: 5px;
}

.booking-map {
    margin: 0;
    height: 100%;
}

.edit-booking-modal {
    padding: 10px;
}

.icon-hand {
    cursor: pointer;
}

.close-icon {
    text-align: right;
    margin-bottom: 10px;
    color: #00af85;
}

.eb-modal-body .data-grid-table-container,
.spot-selection-map .data-grid-table-container {
    min-height: 267px !important;
}

.spot-selection-map .MuiGrid-container {
    margin-top: 10px !important;
}

.spot-selection-map th:first-child span {
    padding-left: 5px !important;
}

.edit-booking-modal-body .MuiInputBase-input.Mui-disabled {
    color: #555555;
}

.eb-search-result-container .data-grid-table-container-list {
    flex: 0;
}

.additionalAmenities {
    color: #004600;
}

.matchingAmenities {
    color: #000000;
}

.missingAmenities {
    color: #b10000;
    text-decoration: line-through;
}

.amount-msg {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    color: red;
    font-weight: bold;
    align-content: center;
    align-items: center;
    height: 150px;
    justify-content: center;
}

.calendar-block-container-update {
    width: 400px;
    padding: 15px !important;
}

.save-button-container.row{
    margin-right: 0px !important;
}

.modifyBooking .data-grid-table-container{
    min-height: 110px !important;
}

.modifyBooking .data-grid-table-container .MuiTableBody-root .MuiTableCell-root{
    border-bottom: 0px;
}

.h5-font{
    font-weight: 700;
    font-size: 16px;
    line-height: 21.86px;
    padding: 10px;
}

.b-l-1px-solid {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.modifyBookingClass.MuiTableRow-root.Mui-selected{
    background-color: #F0F0F0 !important;
}
.removeRowClick.MuiTableRow-hover{
    cursor: default !important;
}
.modifyBookingHeader .edit-booking-appbar-title.MuiTypography-h6 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #323232;
}

.modifyBookingHeader svg{
    color: #323232;
}

.modifyBookingDialog .MuiDialog-paperFullScreen {
    height: 104%;
}

.modifyBookingDialog .flex-display {
    display: flex;
    flex-direction: column;
}

.remove-flex-1 {
    flex: none;
}

.searchList:hover{
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
    border-radius: 5px;
}

.searchList label:hover{
    cursor: pointer;
}

.container-color {
    color: #323232;
}
.booking-details-label {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8A959E;
    line-height: 18px;
}

.booking-details-fields {
    font-weight: 400;
    font-size: 13px;
    color: #323232;
    line-height: 18px;
}

.booking-details-paid-hourly {
    font-weight: 700;
    font-size: 13px;
    color: #00AF85;
    line-height: 18px;
    text-align: start;
    font-style: bold;
}

.booking-details-reserved {
    font-weight: 700;
    font-size: 13px;
    color: #000000;
    line-height: 18px;
    text-align: start;
    font-style: bold;
}

.booking-details-subscription-cancelled {
    font-weight: 400;
    font-size: 13px;
    color: #F7C10F;
    line-height: 18px;
    text-align: start;
    font-style: bold;
}

.booking-details-cancelled {
    font-weight: 700;
    font-size: 12px;
    color: #DC2626;
    line-height: 18px;
    text-align: start;
    font-style: bold;
}

.booking-details-button-1 {
    border-radius: 8px;
    margin-right: 14px;
    border: 1px solid #00AF85;
    font-size: 15px;
    font-weight: 700;
}

.booking-details-button-2 {
    flex: 1;
}

.booking-details-bg {
    margin-left: 5px;
    margin-top: 10px;
    overflow-y: scroll;
}

.booking-height {
    height: 100vh;
    width: 380px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
}

.booking-bottom-bg {
    margin-top: 24px;
    margin-bottom: 20px;
}

.divider-bg {
    height: 1px;
    /* stroke-width: 0.5px; */
    background-color: #E0E0E0;
    margin-right: 35px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.booking-details-header {
    display: flex;
    margin-bottom: 13px;
}

.booking-details-header-label {
    flex: 4;
    font-style: bold;
    color: #000000;
    align-self: center;
    font-size: 24px;
    font-weight: 700;
}


.booking-details-header-close {
    flex: 1;
}

.booking-activity-circle {
    width: 7px;
    height: 7px;
    border: 1px solid #C2C6CA;
    border-radius: 50%;
}

.booking-activity-circle-complete {
    width: 7px;
    height: 7px;
    background-color: #00AF85;
    border-radius: 50%;
}

.booking-activity-circle-BG {
    display: flexbox;
    flex-direction: column;
    /* align-items: stretch; */
    margin-top: 7px;
}


.activity-BG {
    display: flex;
    flex-direction: row;
    margin-right: 45px;
}

.booking-detail-verticle-line {
    width: 1px;
    /* display: flexbox; */
    background-color: #C2C6CA;
    margin: auto;
    /* /* margin-top: 2px; */
    height: 100%;
}

.booking-detail-notes-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: start;
    color: #323232;
}

    .booking-detail-notes-message::after {
        content: attr(data-after);
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: start;
        color: #6B7278;
    }



.booking-detail-activity-1 {
    color: #111012;
    font-style: mixed;
    font-size: 12px;
    font-weight: 700;
    display: inline;
    height: 100%;
}

.booking-detail-activity-2 {
    display: inline;
    color: #111012;
    font-style: mixed;
    font-size: 12px;
    font-weight: 400;
    margin-left: 2px;
}

.booking-detail-activity-3 {
    color: #6B7278;
    font-style: mixed;
    font-size: 12px;
    font-weight: 400;
}

.wordWrap-100 {
    min-width: 100px;
    word-break: break-all;
}