.button-day-selector {
    font-size: 12px !important;
    min-width: 30px !important;
    width: 30px;
    height: 30px;
}

.button-day-selector:disabled {
    background-color: #c8c8c8 !important;
    opacity: 0.5;
}

.multi-schedule-right-panel {
    width: 40px;
}

.multi-schedule-right-panel > button {
    min-width: 0 !important;
}

.internal-schedule-panel .MuiExpansionPanelSummary-root.Mui-expanded {
    height: 20px !important;
    min-height: 20px !important;
}
.schedule-panel {
    border: 1px solid transparent;
}
.schedule-panel .MuiExpansionPanelDetails-root {
    padding-top: 0;
}
.schedule-panel.error {
    border-color: #f00;
}
.schedule-panel.error .errorText {
    font-size: 0.7em;
    color: #f00;
}