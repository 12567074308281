.map-marker-holder {
    position: relative;
}
.map-marker-image {
    position: absolute;
    bottom: 0px;
    left: -13px;
    cursor: pointer;
}
.map-marker-text {
    position: absolute;
    pointer-events: none;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    white-space: nowrap;
    transform: translate(-50%, -64px);
    padding: 0 4px;
    z-index: 1;
}