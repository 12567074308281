.treeview-filter .MuiInputBase-input{
    height: .5em!important;
}

.treeview-filter{
    padding: 5px;
}

.treeview-container{
    padding: 10px 0 0 15px;
    overflow-y: auto;
}

.treeview-container-default{
    max-height: 100%;
}

.treeview-container-355{
    max-height: 355px!important;
}

.treeview-container-285{
    max-height: 285px!important;
}

.treeview-container-219{
    max-height: 219px!important;
}

.treeview-container .Mui-checked{
    color: #00b188!important;
}

.treeview-container .MuiCheckbox-indeterminate{
    color: #00b188!important;
}